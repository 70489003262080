import React, { useEffect, useState } from 'react'
import './recipes.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRecipes, getNextRecipes, resetRecipes, resetRecipeLoader, getPlatingImages, getRecipeImages, getFitnessRecipes, getNextFitnessRecipe, getFitnessImages, addRecipeFavorites, addFitnessFavorites } from '../../Redux/ActionCreators/homepageAction';
import Slider from 'react-slick';
import { Accordion, Modal } from 'react-bootstrap';
import Lightbox from '../../utils/lightBox';
import { Helmet } from 'react-helmet';
import { extractNamesFromUrls } from '../../utils/miscellneous';


export default function Recipes() {
    const [page, setPage] = useState(1);
    const [active, setActive] = useState('-1');
    const [openBox, setOpenBox] = useState(false);
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [isLogin, setIsLogin] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { recipes, hash, isLoadingRecipe, recipeComplete } = useSelector(state => state.home);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            await dispatch(resetRecipes());
            const { ...newObj } = location.state.inputData;
            if (location.state.type === 'recipes') {
                const res = await dispatch(getRecipes(newObj));
                if (res?.type === 'GET_RECIPES') {
                    for (var i = 0; i < res.payload.rows?.length; i++) {
                        await dispatch(getRecipeImages(res.payload.rows[i], i));
                    }
                    await setCount(0);
                }
            } else {
                const res = await dispatch(getFitnessRecipes(newObj));
                if (res?.type === 'GET_FITNESS_RECIPES') {
                    for (var j = 0; j < res.payload.rows?.length; j++) {
                        dispatch(getFitnessImages(res.payload.rows[j], j));
                    }
                    await setCount(0);
                }
            }

        }
        firstRender();
    }, [])

    const getMoreRecipes = async () => {
        await dispatch(resetRecipeLoader());
        setPage(page + 1)
        if (location.state.type === 'recipes') {
            let inputData = {
                activeMasterCatalog: location.state.inputData?.activeMasterCatalog,
                activeMasterSlug: location.state.inputData?.activeMasterSlug,
                activeCatalog: location.state.inputData.activeCatalog,
                activeSubCatalog: location.state.inputData.activeSubCatalog,
                subCataLogRemark: location.state.inputData.subCataLogRemark,
                activeCuisine: location.state.inputData.activeCuisine,
                cuisineRemark: location.state.inputData.cuisineRemark,
                activeProductTypes: location.state.inputData.activeProductTypes,
                productTypeRemark: location.state.inputData.productTypeRemark,
                activeProducts: location.state.inputData.activeProducts,
                productRemark: location.state.inputData.productRemark,
                otherNotes: location.state.inputData.otherNotes,
                keyword: location.state.inputData.keyword,
                page: page + 1,
                hash: hash,
            }
            const res = await dispatch(getNextRecipes(inputData));
            if (res?.type === 'GET_NEXT_RECIPES') {
                const rec = recipes.concat(res.payload.rows?.map(el => [{ ...el, recipe_image: '/images/placeholder-image.gif', images: [] }][0]))
                let start = count + res.payload.rows?.length
                for (var i = start; i < rec?.length; i++) {
                    await dispatch(getRecipeImages(rec[i], i));
                }
            }
        } else {
            let inputData = {
                "activeMasterCatalog": location.state.inputData.activeMasterCatalog,
                "activeMasterSlug": location.state.inputData.activeMasterSlug,
                "activeCatalog": location.state.inputData.activeCatalog,
                "activeSubCatalog": location.state.inputData.activeSubCatalog,
                "subCataLogRemark": location.state.inputData.subCataLogRemark,
                "age": Number(location.state.inputData.age),
                "health_conditions": location.state.inputData.healthCondition,
                "goals": location.state.inputData.goals,
                "mealType": location.state.inputData?.mealType,
                "mealTypeRemark": location.state.inputData.mealTypeRemark,
                "activeCuisine": location.state.inputData.activeCuisine,
                "cuisineRemark": location.state.inputData.cuisineRemark,
                "otherNotes": location.state.inputData.otherNotes,
                food_allergies: location.state.food_allergies,
                "keyword": location.state.inputData.keyword,
                "page": page + 1,
                "hash": ""
            }
            const res = await dispatch(getNextFitnessRecipe(inputData));
            if (res?.type === 'GET_NEXT_FITNESS_RECIPES') {
                const rec = recipes.concat(res.payload.rows?.map(el => [{ ...el, fitness_image: '/images/placeholder-image.gif', images: [] }][0]))
                let start = count + res.payload.rows?.length
                for (var j = start; j < rec?.length; j++) {
                    await dispatch(getFitnessImages(rec[j], j));
                }
            }
        }

    }

    const getPlatingImage = async (detail, i) => {
        await setIsLoading(true);
        await dispatch(getPlatingImages(detail, i, 'recipe'));
        await setIsLoading(false);
    }

    const goBack = () => {
        navigate('/')
    }

    const recipeloaderImages = [
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/2.png',
        './images/loader-images/recipes/3.png',
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/4.png',
        './images/loader-images/recipes/5.png',
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/6.png',
        './images/loader-images/recipes/7.png',
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/8.png',
        './images/loader-images/recipes/9.png',
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/10.png',
        './images/loader-images/recipes/11.png',
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/12.png',
        './images/loader-images/recipes/13.png',
        './images/loader-images/recipes/loader.gif',
        './images/loader-images/recipes/14.png',
        './images/loader-images/recipes/15.png',
        './images/loader-images/recipes/loader.gif',
    ];
    const fitnessloaderImages = [
        './images/loader-images/fitness/loader.gif',
        './images/loader-images/fitness/1.jpg',
        './images/loader-images/fitness/2.jpg',
        './images/loader-images/fitness/loader.gif',
        './images/loader-images/fitness/3.jpg',
        './images/loader-images/fitness/4.jpg',
        './images/loader-images/fitness/loader.gif',
        './images/loader-images/fitness/5.jpg',
        './images/loader-images/fitness/6.jpg',
        './images/loader-images/fitness/loader.gif',
        './images/loader-images/fitness/7.jpg',
        './images/loader-images/fitness/8.jpg',
        './images/loader-images/fitness/loader.gif',
        './images/loader-images/fitness/9.jpg',
        './images/loader-images/fitness/10.jpg',
        './images/loader-images/fitness/loader.gif',
    ];

    var settings = {
        speed: 4000,
        autoplay: true,
        fade: true,
        pauseOnHover: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const handleAccordion = (i) => {
        if (Number(active) === i) {
            setActive(-1);
        } else {
            setActive(i);
        }
    }

    const openImage = (e, img) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenBox(true);
        setImage(img)
    }

    const marked = async (id, val) => {
        if (!localStorage.getItem('userAuthToken')) {
            setIsLogin(true);
        } else {
            if (location.state.type === 'recipes') {
                let obj = {
                    "recipeId": id,
                    "isFavorite": val
                }
                await dispatch(addRecipeFavorites(obj));
            } else {
                let obj = {
                    "fitnessWorkoutId": id,
                    "isFavorite": val
                }
                await dispatch(addFitnessFavorites(obj));
            }
        }
    }

    return (
        < div className='content-holder' >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recipes | Healthy Dinner, Vegan, Desserts & More | Mashup Now</title>
                <meta name="description" content="Discover a wide range of recipes on Mashup Now! Browse healthy recipes for dinner, weight loss diets, vegan dishes, and quick snacks." />
                <link rel="canonical" href="https://www.mashupnow.com/recipes" />
            </Helmet>
            <div className='banner-image' >
                <img src={location?.state?.type === 'recipes' ? './images/background-images/recipes.jpg' : './images/background-images/fitness.jpg'} alt='' />
                <h5>{location.state.type === 'recipes' ? 'Recipes' : 'Fitness & Mental Health'}</h5>
            </div>

            {
                <div className="content">
                    {location.state.type === 'recipes' ?
                        //For Recipes
                        (recipes ?
                            <Accordion defaultActiveKey={`${active}`} id='accord' >
                                {
                                    recipes?.map((el, i) => {
                                        return (
                                            <Accordion.Item key={i} eventKey={`${i}`}>
                                                <Accordion.Header className={Number(active) === i ? 'active-acc' : ''} onClick={() => handleAccordion(i)}>
                                                    <div className='d-flex justify-content-between w-100 flex-wrap' style={{ background: Number(active) === i ? '#ffefe5' : '' }}>
                                                        <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                            <h2 className='d-flex justify-content-between'>{el?.recipe_name}
                                                                {el.isFavorite ?
                                                                    <img src='./svg-icons/heart-filled.svg' alt='' width={30} onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                        marked(el.recipeId, false)
                                                                    }} /> : <img src='./svg-icons/favorite-active-icon.svg' alt='' width={30} onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                        marked(el.recipeId, true)
                                                                    }} />} </h2>
                                                            {window.innerWidth < 767 &&
                                                                <div className='recipe-img-box'>
                                                                    <img src={el.recipe_image} alt='' className='recipe-img' />
                                                                </div>
                                                            }
                                                            <div className='mt-4'>
                                                                {
                                                                    el.ingredients && (
                                                                        <div>
                                                                            <h3>Ingredients: (Serving {el.serving})</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.ingredients.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        {window.innerWidth > 767 ?
                                                            <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                                <div className='recipe-img-box'>
                                                                    <img src={el.recipe_image} alt='' className='recipe-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, el.recipe_image) : null} />
                                                                </div>
                                                            </div> :
                                                            null
                                                        }
                                                    </div>

                                                </Accordion.Header>
                                                <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.instructions && (
                                                                        <div>
                                                                            <h3>Instructions:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.instructions.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.suggestions && (
                                                                        <div>
                                                                            <h3>Suggestions</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.suggestions.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.wellness_tips && (
                                                                        <div>
                                                                            <h3>Wellness Tips</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.wellness_tips.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.plating_creativity && (
                                                                        <div>
                                                                            <h3>Plating Creativity</h3>
                                                                            <p>
                                                                                {el?.plating_creativity.map(item => <div key={item}>{item}</div>)}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }

                                                                {!el?.images?.length ?
                                                                    <div className='view-img'>
                                                                        <button onClick={() => getPlatingImage(el, i)} disabled={isLoading}>{isLoading ? 'Loading Images ...' : 'View Images'}</button>
                                                                    </div> :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='recipe-right col-md-6 col-12'>
                                                            <div className='mt-4'>
                                                                {
                                                                    el?.nutrition && (
                                                                        <div>
                                                                            <h3>Nutrition:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el?.nutrition.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.burn_calories && (
                                                                        <div>
                                                                            <h3>Burn Calories</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.burn_calories.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={window.innerWidth > 767 ? 'row mt-4 ms-2' : 'row ps-0 mt-4'}>
                                                        {el?.images?.length ?
                                                            el?.images.slice(0, 5)?.map((image, index) => {
                                                                return (
                                                                    <div key={index} className={index === 0 ? (window.innerWidth > 767 ? 'col-md-2 col-6  mt-2' : 'col-md-2 col-6 ps-0 mt-2') : (window.innerWidth > 767 ? 'col-md-2 col-6 ps-0 padd mt-2' : 'col-md-2 col-6 ps-0 padd1 mt-2')} >
                                                                        <img src={image} alt='' className='plating-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, image) : null} />
                                                                    </div>
                                                                )
                                                            }) :
                                                            null
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion > :
                            null) :
                        //for Fitness
                        (recipes ?
                            <Accordion defaultActiveKey={`${active}`} id='accord' >
                                {
                                    recipes?.map((el, i) => {
                                        return (
                                            <Accordion.Item key={i} eventKey={`${i}`}>
                                                <Accordion.Header className={Number(active) === i ? 'active-acc' : ''} onClick={() => handleAccordion(i)}>
                                                    <div className='d-flex justify-content-between w-100 flex-wrap' style={{ background: Number(active) === i ? '#ffefe5' : '' }}>
                                                        <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                            <h2 className='d-flex justify-content-between'>{el.fitness_type}
                                                                {el.isFavorite ? <img src='./svg-icons/heart-filled.svg' alt='' width={30} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    marked(el.fitnessWorkoutId, false)
                                                                }} /> : <img src='./svg-icons/favorite-active-icon.svg' alt='' width={30} onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    marked(el.fitnessWorkoutId, true)
                                                                }} />} </h2>
                                                            {window.innerWidth < 767 &&
                                                                <div className='recipe-img-box'>
                                                                    <img src={el.fitness_image} alt='' className='recipe-img' />
                                                                </div>
                                                            }
                                                            <div className='mt-4'>
                                                                {
                                                                    el.fitness_duration && (
                                                                        <div>
                                                                            <h3>Duration:</h3>
                                                                            <p className='ps-0'>{el.fitness_duration}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.fitness_frequency && (
                                                                        <div>
                                                                            <h3>Frequency:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.fitness_frequency.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        {window.innerWidth > 767 ?
                                                            <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                                <div className='recipe-img-box'>
                                                                    <img src={el.fitness_image} alt='' className='recipe-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, el.fitness_image) : null} />
                                                                </div>
                                                            </div> :
                                                            null
                                                        }
                                                    </div>

                                                </Accordion.Header>
                                                <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-12'>

                                                            <div className='mt-4'>
                                                                {
                                                                    el.fitness_instructions && (
                                                                        <div>
                                                                            <h3>Fitness Instructions:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.fitness_instructions.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.repetition_sets && (
                                                                        <div>
                                                                            <h3>Repetitions & sets:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.repetition_sets.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='recipe-right col-md-6 col-12'>
                                                            {location.state.selectedOption === 'yes' &&
                                                                <div className='mt-4'>
                                                                    {
                                                                        el?.fitness_diet && (
                                                                            <div>
                                                                                <h3>Diet Suggestions:</h3>
                                                                                <p dangerouslySetInnerHTML={{ __html: el?.fitness_diet.replaceAll("\n", "<br>") }}></p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            }
                                                            <div className='mt-4'>
                                                                {
                                                                    el.fitness_suggestions && (
                                                                        <div>
                                                                            <h3>Fitness Suggestions:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.fitness_suggestions.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.fitness_tips && (
                                                                        <div>
                                                                            <h3>Tips:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.fitness_tips.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.fitness_disclaimer && (
                                                                        <div>
                                                                            <h3>Disclaimer:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.fitness_disclaimer.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={window.innerWidth > 767 ? 'row mt-4 ms-2' : 'row ps-0 mt-4'}>
                                                        {el?.images?.length ?
                                                            el?.images.slice(0, 5)?.map((image, index) => {
                                                                return (
                                                                    <div key={index} className={index === 0 ? (window.innerWidth > 767 ? 'col-md-2 col-6  mt-2' : 'col-md-2 col-6 ps-0 mt-3') : (window.innerWidth > 767 ? 'col-md-2 col-6 ps-0 padd mt-2' : 'col-md-2 col-6 ps-0 padd1 mt-3')} >
                                                                        <img src={image} alt='' className='plating-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, image) : null} />
                                                                        <p className='exec-name mt-2'>{extractNamesFromUrls(image)}</p>
                                                                    </div>
                                                                )
                                                            }) :
                                                            null
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion > :
                            null)

                    }
                    {
                        isLoadingRecipe ?
                            <div className='text-center'>
                                <Slider {...settings}>
                                    {location.state.type === 'recipes' ?
                                        recipeloaderImages?.map((el, index) => {
                                            return (
                                                <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                    <img src={el} className="d-block loader-img" alt="..." />
                                                </div>
                                            )
                                        })
                                        :
                                        fitnessloaderImages?.map((el, index) => {
                                            return (
                                                <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                    <img src={el} className="d-block loader-img" alt="..." />
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                            :
                            recipes?.length === 0 ?
                                <div className='no-recipes-found'>No data found<br /><br />
                                    <button className='go-back-btn' onClick={goBack}>Go Back</button>
                                </div> :

                                <div className='text-center'>
                                    {
                                        (recipeComplete) ? null :
                                            (location.state.type === 'recipes' ?
                                                <button className='more-btn' onClick={getMoreRecipes}>Get More Recipes</button> :
                                                <button className='more-btn' onClick={getMoreRecipes}>Get More Data</button>
                                            )
                                    }
                                    &nbsp;&nbsp; <button className='go-back-btn' onClick={goBack}>Go Back</button>
                                </div>
                    }

                </div >
            }
            {/* ########################## Lightbox modal ####################### */}
            <Lightbox
                openBox={openBox}
                image={image}
                closeBox={() => setOpenBox(false)}
            />

            {/* ########## Redirect to login ############### */}
            <Modal
                show={isLogin}
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4" >
                    <h1>
                        Please login to favorite and save in your account.
                    </h1>
                    <div className="save-btn">
                        <button onClick={() => {
                            navigate('/login')
                        }}>Login</button>&nbsp;&nbsp;&nbsp;
                        <button style={{ backgroundColor: '#999', color: "#fff" }} onClick={() => {
                            setIsLogin(false)
                        }}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
