import React, { useEffect, useState } from 'react';
import './myfavorite.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, } from 'react-bootstrap';
import { getFavoriteFitness, removeFavFitness } from '../../Redux/ActionCreators/homepageAction';
import TablePagination from '../../utils/tablePagination';
import Lightbox from '../../utils/lightBox';
import { extractNamesFromUrls } from '../../utils/miscellneous';


export default function FavoriteFitness() {
    const [active, setActive] = useState('-1');
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [openBox, setOpenBox] = useState(false);
    const [image, setImage] = useState('');
    const dispatch = useDispatch();
    const { favFitness, isLoadingFavFitness } = useSelector(state => state.home);

    useEffect(() => {
        dispatch(getFavoriteFitness(1, ''));
    }, [])

    const searchKeyword = async () => {
        setPage(1);
        await dispatch(getFavoriteFitness(1, search));
    }

    const resetkeyword = async () => {
        setSearch('');
        await dispatch(getFavoriteFitness(1, ''));
    }


    const goToPrev = () => {
        setPage(page - 1);
        dispatch(getFavoriteFitness(page - 1, search));
    }

    const goToNext = () => {
        setPage(page + 1);
        dispatch(getFavoriteFitness(page + 1, search));
    }

    const removeFavorites = async (fid, id) => {
        let obj = {
            "fitnessWorkoutId": fid,
            "isFavorite": false
        }
        await dispatch(removeFavFitness(obj, id));
    }

    const handleAccordion = (i) => {
        if (Number(active) === i) {
            setActive(-1);
        } else {
            setActive(i);
        }
    }

    const openImage = (e, img) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenBox(true);
        setImage(img)
    }

    return (
        <div className='favorite-recipes'>
            <h2 className="d-flex">
                Favorite Fitness
            </h2>
            {favFitness &&
                <div className='fav-header'>
                    <div className='fav-input'>
                        <input type='search' placeholder='Search By Name' value={search} className='form-control' onChange={(e) => setSearch(e.target.value)} />
                        <button onClick={searchKeyword} disabled={!search} > <img src='./svg-icons/search-icon.svg' width={20} alt='' /> &nbsp;Search</button>&nbsp;&nbsp;
                        <button onClick={resetkeyword} className='btn-secondary' disabled={!search}> Clear</button>
                    </div>
                </div>}

            < div className='content-holder' >
                <div className='content'>
                    <Accordion defaultActiveKey={`${active}`} id='accord' >
                        {isLoadingFavFitness ?
                            '' :
                            favFitness?.rows?.map((el, i) => {
                                return (
                                    <Accordion.Item key={i} eventKey={`${i}`}>
                                        <Accordion.Header className={Number(active) === i ? 'active-acc' : ''} onClick={() => handleAccordion(i)}>
                                            <div className='d-flex justify-content-between w-100 flex-wrap' style={{ background: Number(active) === i ? '#ffefe5' : '' }}>
                                                <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                    <h2 className='d-flex justify-content-between align-items-center'>{el.fitnessWorkout?.name}
                                                        <img src='./svg-icons/heart-filled.svg' alt='' className='mt-15' onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            removeFavorites(el.fitnessWorkout?.id, el.id);
                                                        }} />
                                                    </h2>
                                                    {window.innerWidth < 767 &&
                                                        <div className='recipe-img-box'>
                                                            <img src={el.fitnessWorkout.data.fitness_images[0] || '/images/placeholder-image.gif'} alt='' className='recipe-img' />
                                                        </div>
                                                    }
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.fitness_duration && (
                                                                <div>
                                                                    <h3>Duration:</h3>
                                                                    <p className='ps-0'>{el.fitnessWorkout.data.fitness_duration}</p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.fitness_frequency && (
                                                                <div>
                                                                    <h3>Frequency:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data.fitness_frequency.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {window.innerWidth > 767 ?
                                                    <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                        <div className='recipe-img-box'>
                                                            <img src={el.fitnessWorkout.data.fitness_images[0] || '/images/placeholder-image.gif'} alt='' className='recipe-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, el.recipe_image) : null} />
                                                        </div>
                                                    </div> :
                                                    null
                                                }
                                            </div>

                                        </Accordion.Header>
                                        <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    {/* <div className='mt-4'>
                                                                {
                                                                    el.fitness_type && (
                                                                        <div>
                                                                            <h3>Fitness Type:</h3>
                                                                            <p dangerouslySetInnerHTML={{ __html: el.fitness_type.replaceAll("\n", "<br>") }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div> */}
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.fitness_instructions && (
                                                                <div>
                                                                    <h3>Fitness Instructions:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data.fitness_instructions.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.repetition_sets && (
                                                                <div>
                                                                    <h3>Repetitions & sets:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data.repetition_sets.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.plating_creativity && (
                                                                <div>
                                                                    <h3>Plating Creativity</h3>
                                                                    {/* <p dangerouslySetInnerHTML={{ __html: el.plating_creativity.replaceAll("\n", "<br>") }}></p> */}

                                                                    <p>
                                                                        {el.fitnessWorkout.data?.plating_creativity.map(item => <div key={item}>{item}</div>)}
                                                                    </p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='recipe-right col-md-6 col-12'>
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data?.fitness_diet && (
                                                                <div>
                                                                    <h3>Diet Suggestions:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data?.fitness_diet.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.fitness_suggestions && (
                                                                <div>
                                                                    <h3>Fitness Suggestions:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data.fitness_suggestions.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.fitness_tips && (
                                                                <div>
                                                                    <h3>Tips:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data.fitness_tips.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.fitnessWorkout.data.fitness_disclaimer && (
                                                                <div>
                                                                    <h3>Disclaimer:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.fitnessWorkout.data.fitness_disclaimer.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            <div className={window.innerWidth > 767 ? 'row mt-4 ms-2' : 'row ps-0 mt-4'}>
                                                {el.fitnessWorkout.data.fitness_images?.length ?
                                                    el.fitnessWorkout.data.fitness_images.slice(0, 5)?.map((image, index) => {
                                                        return (
                                                            <div key={index} className={index === 0 ? (window.innerWidth > 767 ? 'col-md-2 col-6  mt-2 text-center' : 'col-md-2 col-6 ps-0 mt-2 text-center') : (window.innerWidth > 767 ? 'col-md-2 col-6 ps-0 padd mt-2 text-center' : 'col-md-2 col-6 ps-0 padd1 mt-2 text-center')} >
                                                                <img src={image} alt='' className='plating-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, image) : null} />
                                                                <p className='exec-name mt-1'>{extractNamesFromUrls(image)}</p>
                                                            </div>
                                                        )
                                                    }) :
                                                    null
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion >
                    {favFitness?.rows?.length > 0 ?
                        <div>
                            <TablePagination
                                goToPrev={goToPrev}
                                goToNext={goToNext}
                                data={favFitness}
                            />
                        </div> :
                        null
                    }
                </div>
            </div>
            {/* ########################## Lightbox modal ####################### */}
            <Lightbox
                openBox={openBox}
                image={image}
                closeBox={() => setOpenBox(false)}
            />
        </div>

    )
}
