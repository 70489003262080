import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../Login/login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { queryAdd } from '../../Redux/ActionCreators/homepageAction';
import { Alert, Snackbar } from '@mui/material';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { Helmet } from 'react-helmet';


const Contactus = () => {
    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    })

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };

    }, [])

    const dispatch = useDispatch();
    const error = useSelector(state => state.error);

    // Define the validation schema using Yup
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Only alphabets and spaces are allowed')
            .required('First Name is required'),
        lastName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Only alphabets and spaces are allowed')
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Invalid email address'
            ),
        phone: Yup.string()
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        message: Yup.string().required('Message is required'),
    });

    // Define the form submission handler
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // Handle form submission here
        const obj = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: String(values.phone),
            message: values.message
        }
        const res = await dispatch(queryAdd(obj));
        if (res?.type === 'ADD_QUERY') {
            resetForm();
        }
    };

    const snackClosed = () => {
        dispatch(clearErrors());
        handleClose();
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(clearErrors());
    };

    return (
        <div className='login-module' style={{ backgroundImage: window.innerWidth > 767 ? '' : `url('./images/background-images/img1.png')` }}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successError}
                autoHideDuration={3000}
                onClose={snackClosed}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successMessage}
                onClose={() => snackClosed()}
                message={error.messageText}
                autoHideDuration={4000}
            >
                {
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {error.messageText}
                    </Alert>}
            </Snackbar>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Get in Touch with Mashup Now for Recipe Ideas</title>
                <meta name="description" content="Have a question about recipes or our platform? Contact Mashup Now for inquiries, support, and culinary inspiration." />
                <link rel="canonical" href="https://www.mashupnow.com/contact-us" />
            </Helmet>
            <div className=''>
                {window.innerWidth > 767 && <img src='./images/background-images/img1.png' className='bg-image' alt='' />}
                <div className='row'>
                    <div className='col-md-7'>
                        <div className="login-text">
                            <section>Berry Good Fact</section>
                            <p className='mt-4'>Blueberries are rich in antioxidants, promoting brain health and reducing aging effects, making them a delicious and nutritious snack option.</p>
                        </div>
                    </div>
                    <div className='col-md-5 d-flex justify-content-center align-items-center'>
                        <div className="login-holder signup">
                            <div className="login-box">
                                <h6 className="text-left">Contact us</h6>
                                <p style={{ color: '#8D8C8F', fontSize: 16, fontWeight: 400 }}>Fill in the form to connect with us</p>
                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                    {() => (
                                        <Form className="signup_form mt-3">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <Field type="text" name="firstName" className="form-control" placeholder="First Name *" />
                                                        <ErrorMessage name="firstName" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <Field type="text" name="lastName" className="form-control" placeholder="Last Name *" />
                                                        <ErrorMessage name="lastName" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <Field type="email" name="email" className="form-control" placeholder="Email Address *" />
                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <Field type="number" name="phone" className="form-control" placeholder="Phone" />
                                                        <ErrorMessage name="phone" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <Field as='textarea' name="message" className="form-control" rows={5} placeholder="Write Something *" />
                                                        <ErrorMessage name="message" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                            </div>
                                            <button type="submit" className='button-color primary-button' >Submit</button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* <div className='py-2 container'>
                <div className='row justify-content-center'>
                    <div className='col-6 col-md-6 col-lg-6 col-xl-6'>
                        <div className='signup_block p-4'>
                            <h3>Contact Us</h3>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {() => (
                                    <Form className="signup_form mt-3">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <label>First Name<span> *</span></label>
                                                <div className="form-group">
                                                    <Field type="text" name="firstName" className="form-control" placeholder="First Name" />
                                                    <ErrorMessage name="firstName" component="div" className="error-message" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <label>Last Name<span> *</span></label>
                                                <div className="form-group">
                                                    <Field type="text" name="lastName" className="form-control" placeholder="Last Name" />
                                                    <ErrorMessage name="lastName" component="div" className="error-message" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <label>Email<span> *</span></label>
                                                <div className="form-group">
                                                    <Field type="email" name="email" className="form-control" placeholder="Email" />
                                                    <ErrorMessage name="email" component="div" className="error-message" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <label>Phone</label>
                                                <div className="form-group">
                                                    <Field type="number" name="phone" className="form-control" placeholder="Phone" />
                                                    <ErrorMessage name="phone" component="div" className="error-message" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12">
                                                <label>Message<span> *</span></label>
                                                <div className="form-group">
                                                    <Field as='textarea' name="message" className="form-control" placeholder="Enter message..." />
                                                    <ErrorMessage name="message" component="div" className="error-message" />
                                                </div>
                                            </div>

                                        </div>
                                        <button type="submit" className='button-color form-control' >Submit</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Contactus;