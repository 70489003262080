import { getApiCall, postApiCall } from '../../utils/requests';
import { returnErrors } from '../ActionCreators/errorAction';
import {
    GET_BANNERS, GET_BANNERS_FOR_MASTER_CATALOGS, GET_MASTER_CATALOGS, GET_CATALOGS, GET_CUISINES, GET_FAQS, GET_PRODUCT_TYPES, GET_PRODUCTS, GET_NEXT_PRODUCTS, GET_RECIPES, GET_NEXT_RECIPES, GET_RECIPE_IMAGES, GET_PLATING_IMAGES, GET_SUBCATALOGS, SET_PRODUCT_TYPES, SET_PRODUCT, RESET_PRODUCTS, GET_MEAL_TYPE,
    RESET_RECIPES, RESET_RECIPES_LOADER, GET_CONTENTS, ADD_QUERY, SET_LANGUAGE, GET_FITNESS_RECIPES, GET_NEXT_FITNESS_RECIPES, GET_FITNESS_IMAGES, RECIPES_FAVORITES, REMOVE_FAV_RECIPES, FITNESS_FAVORITES, REMOVE_FAV_FITNESS, GET_FAV_RECIPES, GET_NEXT_FAV_RECIPES, GET_FAV_FITNESS, GET_NEXT_FAV_FITNESS
} from '../Types/types';


export const setLang = (val) => async (dispatch) => {
    try {
        dispatch({ type: SET_LANGUAGE, payload: val });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getMasterCatalogs = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/master-catalogs/website`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_MASTER_CATALOGS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}


export const getBuildCatalogs = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`/build-catalogs/website?masterCatalogId=${id ? id : ''}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_CATALOGS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getBuildSubCatalogs = (id, type) => async (dispatch) => {
    try {
        let response = await getApiCall(`/build-sub-catalogs/website?buildCatalogId=${id ? id : ""}&masterCatalogTypes=${type ? type : ""}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_SUBCATALOGS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getCuisines = (id) => async (dispatch) => {
    try {
        let response = await getApiCall(`/cuisines/website?buildCatalogId=${id ? id : ''}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_CUISINES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getProductTypes = (id) => async (dispatch) => {
    try {
        let response = await getApiCall(`/product-types/website?buildCatalogId=${id ? id : ""}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_PRODUCT_TYPES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getProducts = (page, keyword, subcatalogid, cuisineId, id, prodType) => async (dispatch) => {
    try {
        const response = await getApiCall(`/products/website?page=${page}&keyword=${keyword}&buildSubCatalogId=${subcatalogid || ''}&cuisineId=${cuisineId || ''}&productTypeId=${id || ''}`);
        const data = response.data.data
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_PRODUCTS, payload: { data, prodType, id } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getNextProducts = (page, keyword, subcatalogid, cuisineId, id, prodType) => async (dispatch) => {
    try {
        const response = await getApiCall(`/products/website?page=${page}&keyword=${keyword}&buildSubCatalogId=${subcatalogid || ''}&cuisineId=${cuisineId || ''}&productTypeId=${id || ''}`);
        const data = response.data.data
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_NEXT_PRODUCTS, payload: { data, prodType, id } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

//Recipes
export const getRecipes = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/recipes/website`, obj);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_RECIPES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: GET_NEXT_RECIPES, payload: [] });
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
        return dispatch({ type: GET_NEXT_RECIPES, payload: [] });
    }
}

export const getNextRecipes = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/recipes/website`, obj);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_NEXT_RECIPES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const resetRecipeLoader = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_RECIPES_LOADER });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const resetRecipes = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_RECIPES });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getRecipeImages = (recipe, id) => async (dispatch) => {
    const obj = {
        "recipeName": recipe.recipe_name,
        "ingredients": recipe.ingredients_array
    }

    try {
        const response = await postApiCall(`/openai-data/recipe-image/website`, obj);
        if (response.data.statusCode === 200) {
            const data = response.data.data?.recipeImage
            return dispatch({ type: GET_RECIPE_IMAGES, payload: { data, id } });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    }
    // try {
    //     // Map over the recipes array and create an array of promises
    //     const promises = recipes.map(async (recipe, index) => {
    //         const response = await postApiCall(`/recipes/recipe-image/website`, {
    //             "recipeName": recipe.recipe_name,
    //             "ingredients": recipe.ingredients_array
    //         });
    //         if (response.data.statusCode === 200) {
    //             const data = response.data.data?.recipeImage;
    //             console.log(data)
    //             dispatch({ type: GET_RECIPE_IMAGES, payload: { data, id: index } });
    //             return { data, id: index }; // Assuming each recipe has an `id` field
    //         } else {
    //             return { error: response.data.message, status: response.status };
    //         }
    //     });

    //     // Wait for all promises to resolve
    //     const results = await Promise.all(promises);
    // }
    catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}



export const getPlatingImages = (el, id, page) => async (dispatch) => {
    let obj = {
        "recipeName": el.recipe_name,
        "platingContent": el.plating_creativity
    }
    try {
        const response = await postApiCall(`/openai-data/plating-images/website`, obj);

        if (response.data.statusCode === 200) {
            const data = response.data.data?.platingImages
            dispatch({ type: GET_PLATING_IMAGES, payload: { data, id, page } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getFavoriteRecipes = (page, search) => async (dispatch) => {
    try {
        const response = await getApiCall(`/openai-data/favorite-recipes?page=${page}&keyword=${search ? search : ""}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_FAV_RECIPES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: GET_NEXT_FAV_RECIPES, payload: [] });
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
        return dispatch({ type: GET_NEXT_FAV_RECIPES, payload: [] });
    }
}


export const addRecipeFavorites = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/favorite-recipes`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, 'Recipe added to my favorites', response.status);
            return dispatch({ type: RECIPES_FAVORITES, payload: obj });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const removeFavRecipes = (obj, id) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/favorite-recipes`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: REMOVE_FAV_RECIPES, payload: { ...obj, id } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}


//Fitness
export const getFitnessRecipes = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/fitness/website`, obj);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_FITNESS_RECIPES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: GET_NEXT_FITNESS_RECIPES, payload: [] });
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
        return dispatch({ type: GET_NEXT_FITNESS_RECIPES, payload: [] });
    }
}


export const getNextFitnessRecipe = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/fitness/website`, obj);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_NEXT_FITNESS_RECIPES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getFitnessImages = (recipe, id) => async (dispatch) => {
    const obj = {
        "exercises": recipe.fitness_instructions_array
    }

    try {
        const response = await postApiCall(`/openai-data/fitness-workout-images/website`, obj);
        if (response.data.statusCode === 200) {
            const data = response.data.data?.fitnessImages
            return dispatch({ type: GET_FITNESS_IMAGES, payload: { data, id } });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    }

    catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getFavoriteFitness = (page, search) => async (dispatch) => {
    try {
        const response = await getApiCall(`/openai-data/favorite-fitness-workouts?page=${page}&keyword=${search ? search : ""}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_FAV_FITNESS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: GET_NEXT_FAV_FITNESS, payload: [] });
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
        return dispatch({ type: GET_NEXT_FAV_FITNESS, payload: [] });
    }
}

export const addFitnessFavorites = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/favorite-fitness-workouts`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, 'Fitness tip added to my favorites', response.status);
            return dispatch({ type: FITNESS_FAVORITES, payload: obj });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const removeFavFitness = (obj, id) => async (dispatch) => {
    try {
        const response = await postApiCall(`/openai-data/favorite-fitness-workouts`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: REMOVE_FAV_FITNESS, payload: { ...obj, id } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getBanners = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/banners/website`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_BANNERS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getBannersForMasterCatalogs = (id) => async (dispatch) => {
    return dispatch({ type: GET_BANNERS_FOR_MASTER_CATALOGS, payload: id });
}

export const getFaqs = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/faqs/website`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_FAQS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getContents = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/contents/website`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_CONTENTS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}



export const queryAdd = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`/user-queries/website`, obj);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response.data.message, response.status);
            return dispatch({ type: ADD_QUERY });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const setActiveType = (obj) => async (dispatch) => {
    try {
        dispatch({ type: SET_PRODUCT_TYPES, payload: obj });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const setActiveProduct = (obj) => async (dispatch) => {
    try {
        dispatch({ type: SET_PRODUCT, payload: obj });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const resetProducts = (id) => async (dispatch) => {
    try {
        dispatch({ type: RESET_PRODUCTS, payload: id });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getMealSubCatalogs = (id, type) => async (dispatch) => {
    try {
        let response = await getApiCall(`/build-sub-catalogs/website?buildCatalogId=${id ? id : ""}&masterCatalogTypes=${type ? type : ""}`);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_MEAL_TYPE, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}
