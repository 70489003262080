import React, { Fragment, useEffect, useState } from 'react';
import './searchHistory.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { getSearchHistory, deleteSearchHisotry } from '../../Redux/ActionCreators/searchHistoryAction';
import TablePagination from '../../utils/tablePagination';
import { useNavigate } from 'react-router-dom';

export default function SearchHistory() {
    const [active, setActive] = useState('-1');
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { searchHistory, } = useSelector(state => state.searchHistory);


    useEffect(() => {
        dispatch(getSearchHistory(page, ''))
    }, []);

    const handleAccordion = (i) => {
        if (Number(active) === i) {
            setActive(-1);
        } else {
            setActive(i);
        }
    }

    const removeSearch = (id) => {
        dispatch(deleteSearchHisotry(id ? id : ''))
    }

    const openContent = (type, data) => {
        navigate('/recipes', { state: { inputData: data, type: type } });
    }


    const goToPrev = () => {
        setPage(page - 1);
        dispatch(getSearchHistory(page - 1, search));
    }

    const goToNext = () => {
        setPage(page + 1);
        dispatch(getSearchHistory(page + 1, search));
    }



    return (
        <div className='search-history-holder'>
            <h2 className='d-flex justify-content-between align-items-center'>Search History
                <button className='clear-btn' onClick={() => removeSearch('')}><img src='./svg-icons/delete-white.svg' alt='' />&nbsp; {window.innerWidth > 767 ? 'Clear History' : 'Clear'} </button>
            </h2>
            <div className='content-holder'>
                <div className='content w-100'>
                    <Accordion defaultActiveKey={`${active}`} id='accord' >
                        {
                            searchHistory?.rows?.map((el, i) => {
                                return (
                                    <Fragment>
                                        {el.masterCatalogType === 'recipes' ?
                                            <Accordion.Item key={i} eventKey={`${i}`}>
                                                <Accordion.Header className={Number(active) === i ? 'active-acc' : 'adapt'} onClick={() => handleAccordion(i)}>
                                                    <div className='container'>
                                                        <div className='delete-row' title="Delete">
                                                            <img src='/svg-icons/delete-color.svg' alt='' onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                removeSearch(el.id)
                                                            }} />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <label>Search Type</label>
                                                                    <h4 className='text-capitalize'>{el.searchType}</h4>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <label>Master Catalogs</label>
                                                                    <h4>{el.inputData?.activeMasterCatalog}</h4>
                                                                </div>
                                                            </div>
                                                            {el.searchType === 'selection' ?
                                                                <Fragment>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Build me a Recipe</label>
                                                                            <h4>{el.inputData?.activeCatalog}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>I Want to make</label>
                                                                            <h4>{el.inputData?.activeSubCatalog}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {el.inputData?.subCataLogRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>SubCataLog Remark</label>
                                                                                <h4>{el.inputData?.subCataLogRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Cuisine</label>
                                                                            <h4>{el.inputData?.activeCuisine ? el.inputData?.activeCuisine : '-'}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {el.inputData?.cuisineRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Cuisine Remark</label>
                                                                                <h4>{el.inputData?.cuisineRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Product Types</label>
                                                                            <h4>{el.inputData?.activeProductTypes ? el.inputData?.activeProductTypes?.map(el => el).join(', ') : ""}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {el.inputData?.productTypeRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Product Type Remark</label>
                                                                                <h4>{el.inputData?.productTypeRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Selected Products</label>
                                                                            <h4>{el.inputData?.activeProducts ? el.inputData?.activeProducts?.map(el => el).join(', ') : ""}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {el.inputData?.productRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Product Remark</label>
                                                                                <h4>{el.inputData?.productRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {el.inputData?.otherNotes &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Something Else</label>
                                                                                <h4>{el.inputData?.otherNotes}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </Fragment> :
                                                                <Fragment>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Keyword</label>
                                                                            <h4>{el.inputData?.keyword}</h4>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                <div className='build-btn' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    openContent('recipes', el.inputData)
                                                                }}>Build me now</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>

                                                </Accordion.Body>
                                            </Accordion.Item> :
                                            <Accordion.Item key={i} eventKey={`${i}`}>
                                                <Accordion.Header className={Number(active) === i ? 'active-acc' : 'adapt'} onClick={() => handleAccordion(i)}>
                                                    <div className='container'>
                                                        <div className='delete-row' title="Delete">
                                                            <img src='/svg-icons/delete-color.svg' alt='' onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                removeSearch(el.id)
                                                            }} />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <label>Search Type</label>
                                                                    <h4 className='text-capitalize'>{el.searchType}</h4>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <div className='form-group'>
                                                                    <label>Master Catalogs</label>
                                                                    <h4>{el.inputData?.activeMasterCatalog}</h4>
                                                                </div>
                                                            </div>
                                                            {el.searchType === 'selection' ?
                                                                <Fragment>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Catalogs</label>
                                                                            <h4>{el.inputData?.activeCatalog}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Sub-Catalogs
                                                                            </label>
                                                                            <h4>{el.inputData?.activeSubCatalog}</h4>
                                                                        </div>
                                                                    </div>
                                                                    {el.inputData?.subCataLogRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>SubCataLog Remark</label>
                                                                                <h4>{el.inputData?.subCataLogRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Personal Information
                                                                            </label>
                                                                            <h4>Age : {el.inputData?.age} yrs </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Health Conditions
                                                                            </label>
                                                                            <h4>{el.inputData?.health_conditions}  </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Goals
                                                                            </label>
                                                                            <h4>{el.inputData?.goals?.map(el => el).join(', ')}  </h4>
                                                                        </div>
                                                                    </div>
                                                                    {el.inputData?.food_allergies
                                                                        && <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Diet/Food Restrictions (Allergies)
                                                                                </label>
                                                                                <h4>{el.inputData?.food_allergies}  </h4>
                                                                            </div>
                                                                        </div>}
                                                                    {el.inputData?.mealType &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Meal Type
                                                                                </label>
                                                                                <h4>{el.inputData?.mealType}  </h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {el.inputData?.mealTypeRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>MealType Remark</label>
                                                                                <h4>{el.inputData?.mealTypeRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {el.inputData?.activeCuisine &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Cuisine</label>
                                                                                <h4>{el.inputData?.activeCuisine ? el.inputData?.activeCuisine : '-'}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {el.inputData?.cuisineRemark &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Cuisine Remark</label>
                                                                                <h4>{el.inputData?.cuisineRemark}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {el.inputData?.otherNotes &&
                                                                        <div className='col-md-3'>
                                                                            <div className='form-group'>
                                                                                <label>Something Else</label>
                                                                                <h4>{el.inputData?.otherNotes}</h4>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </Fragment> :
                                                                <Fragment>
                                                                    <div className='col-md-3'>
                                                                        <div className='form-group'>
                                                                            <label>Keyword</label>
                                                                            <h4 className='text-capitalize'>{el.inputData?.keyword}</h4>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-md-3'>
                                                                <div className='build-btn' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    openContent('fitness', el.inputData)
                                                                }}>Advice me now</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </Accordion >
                    {searchHistory?.rows?.length > 0 ?
                        <div>
                            <TablePagination
                                goToPrev={goToPrev}
                                goToNext={goToNext}
                                data={searchHistory}
                            />
                        </div> :
                        null
                    }
                </div>
            </div>
        </div>
    )
}
