import {
    GET_BANNERS, GET_BANNERS_FOR_MASTER_CATALOGS, GET_MASTER_CATALOGS, GET_CATALOGS, GET_CONTENTS, GET_CUISINES, GET_FAQS, GET_PRODUCTS, GET_NEXT_PRODUCTS, GET_PRODUCT_TYPES, GET_MEAL_TYPE,
    GET_RECIPES, GET_NEXT_RECIPES, GET_RECIPE_IMAGES, GET_PLATING_IMAGES, GET_SUBCATALOGS, SET_PRODUCT_TYPES, SET_PRODUCT, RESET_PRODUCTS, RESET_RECIPES, RESET_RECIPES_LOADER, SET_LANGUAGE,
    GET_FITNESS_RECIPES, GET_NEXT_FITNESS_RECIPES, GET_FITNESS_IMAGES, RECIPES_FAVORITES, REMOVE_FAV_RECIPES, FITNESS_FAVORITES, REMOVE_FAV_FITNESS, GET_FAV_FITNESS, GET_FAV_RECIPES
} from '../Types/types';


let intialState = {
    masterCatalogs: [],
    catalogs: [],
    activeProductTypes: [],
    activeProducts: [],
    activeLanguage: 'English',
    isLoadingRecipe: true,
    languages: [{ label: 'English', value: 'english' }, { label: 'Hindi', value: 'hindi' }, { label: 'French', value: 'french' },
    { label: 'Italian', value: 'italian' }, { label: 'Chinese', value: 'chinese' }, { label: 'Spanish', value: 'spanish' }, { label: 'Portgeous', value: 'Portgeous' }
    ],
    recipes: [],
    recipeComplete: false,
    isLoadingFavFitness: true,
    isLoadingFavRecipes: true
}

export default function homeReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case SET_LANGUAGE:
            return { ...state, activeLanguage: action.payload };
        case GET_BANNERS:
            return { ...state, banners: action.payload.rows, bannerCopy: action.payload.rows };
        case GET_BANNERS_FOR_MASTER_CATALOGS:
            return { ...state, banners: state.bannerCopy?.filter(el => el.masterCatalog?.id === Number(action.payload)) };
        case GET_MASTER_CATALOGS:
            return { ...state, masterCatalogs: action.payload.rows };
        case GET_CATALOGS:
            if (action.payload.rows?.length) {
                stateCopy.catalogs = action.payload.rows
                stateCopy.activeProductTypes = [];
                stateCopy.activeProducts = [];
            } else {
                stateCopy.catalogs = [];
                stateCopy.subCatalogs = [];
                stateCopy.productTypes = [];
                stateCopy.activeProductTypes = [];
                stateCopy.products = [];
                stateCopy.activeProducts = [];
                stateCopy.cuisines = [];
            }
            return stateCopy;
        case GET_SUBCATALOGS:
            return { ...state, subCatalogs: action.payload.rows };
        case GET_CUISINES:
            return { ...state, cuisines: action.payload.rows };
        case GET_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.payload.rows.map((item, i) => ({ ...item, isChecked: i === 0 })),
                activeProductTypes: action.payload.rows.map((item, i) => ({ ...item, isChecked: i === 0 })),
            };
        case RESET_PRODUCTS:
            // state.activeProducts = state.activeProducts.filter(el => el.productTypeId !== action.payload)
            const prodTypeid = state.activeProductTypes.map(el => el.id);
            const id = prodTypeid[prodTypeid?.length - 1]
            return {
                ...state, products: state.activeProducts.filter(el => el.productTypeId === id)
            }
        case GET_PRODUCTS:
            action.payload.data?.rows.forEach(item => {
                const foundIndex = state.activeProducts.find(i => i.id === item.id);
                if (foundIndex) {
                    item.isChecked = true;
                    item.productType = action.payload.prodType
                    item.productTypeId = action.payload.id
                } else {
                    item.isChecked = false;
                    item.productType = action.payload.prodType
                    item.productTypeId = action.payload.id
                }
            })
            return { ...state, products: action.payload.data.rows };
        case GET_NEXT_PRODUCTS:
            action.payload.data?.rows.forEach(item => {
                const foundIndex = state.activeProducts.find(i => i.id === item.id);
                if (foundIndex) {
                    item.isChecked = true;
                    item.productType = action.payload.prodType
                    item.productTypeId = action.payload.id
                } else {
                    item.isChecked = false;
                    item.productType = action.payload.prodType
                    item.productTypeId = action.payload.id
                }
            })
            return { ...state, products: stateCopy.products.concat(action.payload.data.rows) };
        case GET_MEAL_TYPE:
            return { ...state, mealTypes: action.payload.rows };
        case GET_RECIPES:
            if (action.payload.rows?.length) {
                return { ...state, recipes: action.payload.rows?.map(el => [{ ...el, images: [], recipe_image: '/images/placeholder-image.gif' }][0]), hash: action.payload.hash, isLoadingRecipe: false, recipeComplete: false };
            } else {
                return { ...state, recipeComplete: true, isLoadingRecipe: false };
            }
        case GET_NEXT_RECIPES:
            if (action.payload.rows?.length) {
                return { ...state, recipes: state.recipes.concat(action.payload.rows?.map(el => [{ ...el, images: [], recipe_image: '/images/placeholder-image.gif' }][0])), hash: action.payload.hash, isLoadingRecipe: false, recipeComplete: false };
            } else {
                return { ...state, recipeComplete: true, isLoadingRecipe: false };
            }
        case GET_RECIPE_IMAGES:
            stateCopy.recipes.forEach((el, i) => {
                if (i === action.payload.id) {
                    el.recipe_image = action.payload.data;
                }
            })
            return stateCopy;
        case GET_PLATING_IMAGES:
            if (action.payload.page === 'recipe') {
                state.recipes.forEach((el, i) => {
                    if (i === action.payload.id) {
                        el.images = action.payload.data;
                    }
                })
                return { ...state, recipes: state.recipes };
            } else {

                state.favRecipes.rows.forEach((el, i) => {
                    if (i === action.payload.id) {
                        // if (!el.images) {
                        //     el.images = [];
                        // }
                        el.images = action.payload.data;
                    }
                });
                return { ...state, favRecipes: state.favRecipes };
            }
        case GET_FITNESS_RECIPES:
            if (action.payload.rows?.length) {
                return { ...state, recipes: action.payload.rows?.map(el => [{ ...el, images: [], fitness_image: '/images/placeholder-image.gif' }][0]), hash: action.payload.hash, isLoadingRecipe: false, recipeComplete: false };
            } else {
                return { ...state, recipeComplete: true, isLoadingRecipe: false };
            }
        case GET_NEXT_FITNESS_RECIPES:
            if (action.payload.rows?.length) {
                return { ...state, recipes: state.recipes.concat(action.payload.rows?.map(el => [{ ...el, images: [], fitness_image: '/images/placeholder-image.gif' }][0])), hash: action.payload.hash, isLoadingRecipe: false, recipeComplete: false };
            } else {
                return { ...state, recipeComplete: true, isLoadingRecipe: false };
            }
        case GET_FITNESS_IMAGES:
            stateCopy.recipes.forEach((el, i) => {
                if (i === action.payload.id) {
                    el.fitness_image = action.payload.data[0];
                    el.images = action.payload.data
                }
            })
            return stateCopy;
        case GET_FAV_FITNESS:
            return { ...state, favFitness: action.payload, isLoadingFavFitness: false };
        case GET_FAV_RECIPES:
            return { ...state, favRecipes: action.payload, isLoadingFavRecipes: false };
        case RECIPES_FAVORITES:
            stateCopy.recipes.forEach(el => {
                if (el.recipeId === action.payload.recipeId) {
                    el.isFavorite = action.payload.isFavorite;
                }
            })
            return stateCopy;
        case REMOVE_FAV_RECIPES:
            return {
                ...state, favRecipes: { ...state.favRecipes, rows: state.favRecipes?.rows?.filter(el => el.id !== action.payload.id) }
            }
        case FITNESS_FAVORITES:
            stateCopy.recipes.forEach(el => {
                if (el.fitnessWorkoutId === action.payload.fitnessWorkoutId) {
                    el.isFavorite = action.payload.isFavorite;
                }
            })
            return stateCopy;
        case REMOVE_FAV_FITNESS:
            return {
                ...state, favFitness: { ...state.favFitness, rows: state.favFitness?.rows?.filter(el => el.id !== action.payload.id) }
            }
        case RESET_RECIPES:
            return {
                ...state, recipes: "", hash: "", isLoadingRecipe: true, recipeComplete: false
            }
        case RESET_RECIPES_LOADER:
            return {
                ...state, isLoadingRecipe: true
            }
        case GET_FAQS:
            return { ...state, faqs: action.payload.rows };
        case GET_CONTENTS:
            return { ...state, contents: action.payload };
        case SET_PRODUCT_TYPES:
            stateCopy.productTypes.forEach(el => {
                if (el.id === action.payload.id) {
                    el.isChecked = true
                }
            })
            if (!stateCopy.activeProductTypes.some(el => el.id === action.payload.id)) {
                const data = { ...action.payload, isChecked: !action.payload.isChecked }
                stateCopy.activeProductTypes = [...stateCopy.activeProductTypes, data];
            } else {
                stateCopy.activeProductTypes.forEach(el => {
                    if (el.id === action.payload.id) {
                        el.isChecked = true
                    }
                })
            }
            return stateCopy;
        case SET_PRODUCT:
            stateCopy.products.forEach(el => {
                if (el.id === action.payload.id) {
                    el.isChecked = !el.isChecked
                }
            })
            if (!stateCopy.activeProducts.some(el => el.id === action.payload.id)) {
                const data = { ...action.payload, isChecked: !action.payload.isChecked }
                stateCopy.activeProducts = [...stateCopy.activeProducts, data];
            } else {
                stateCopy.activeProducts.forEach(el => {
                    if (el.id === action.payload.id) {
                        el.isChecked = !el.isChecked
                    }
                })
            }

            return stateCopy;


        default:
            return state;
    }
}
