//Login
export const USER_LOGIN = 'USER_LOGIN';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';


//Signup 
export const USER_REGISTER = 'USER_REGISTER';

//add query
export const ADD_QUERY = 'ADD_QUERY';


//PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LOGOUT_USER = 'LOGOUT_USER';

//Home
export const GET_MASTER_CATALOGS = 'GET_MASTER_CATALOGS';
export const GET_CATALOGS = 'GET_CATALOGS';
export const GET_SUBCATALOGS = 'GET_SUBCATALOGS';
export const GET_CUISINES = 'GET_CUISINES';
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_NEXT_PRODUCTS = 'GET_NEXT_PRODUCTS';
export const GET_RECIPES = 'GET_RECIPES';
export const GET_NEXT_RECIPES = 'GET_NEXT_RECIPES';
export const GET_RECIPE_IMAGES = 'GET_RECIPE_IMAGES';
export const GET_PLATING_IMAGES = 'GET_PLATING_IMAGES';
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES';
export const SET_PRODUCT = 'SET_PRODUCT';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const GET_MEAL_TYPE = 'GET_MEAL_TYPE';
export const RESET_RECIPES = 'RESET_RECIPES';
export const RESET_RECIPES_LOADER = 'RESET_RECIPES_LOADER';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_FITNESS_RECIPES = 'GET_FITNESS_RECIPES';
export const GET_NEXT_FITNESS_RECIPES = 'GET_NEXT_FITNESS_RECIPES';
export const GET_FITNESS_IMAGES = 'GET_FITNESS_IMAGES';
export const RECIPES_FAVORITES = 'RECIPES_FAVORITES';
export const REMOVE_FAV_RECIPES = 'REMOVE_FAV_RECIPES';
export const FITNESS_FAVORITES = 'FITNESS_FAVORITES';
export const REMOVE_FAV_FITNESS = 'REMOVE_FAV_FITNESS';
export const GET_FAV_RECIPES = 'GET_FAV_RECIPES';
export const GET_NEXT_FAV_RECIPES = 'GET_NEXT_FAV_RECIPES';
export const GET_FAV_FITNESS = 'GET_FAV_FITNESS';
export const GET_NEXT_FAV_FITNESS = 'GET_NEXT_FAV_FITNESS';

//Search history
export const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY';
export const DELETE_SEARCH_HISTORY = 'DELETE_SEARCH_HISTORY';

//BANNER
export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNERS_FOR_MASTER_CATALOGS = 'GET_BANNERS_FOR_MASTER_CATALOGS';
//PRODUCTS



//FAQ
export const GET_FAQS = 'GET_FAQS';

//contents
export const GET_CONTENTS = 'GET_CONTENTS';


//Subscriptions
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_COUPONS = 'GET_COUPONS';
export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const PAYMENT_PROCEED = 'PAYMENT_PROCEED';

//My subscriptions
export const GET_MY_SUBSCRIPTIONS = 'GET_MY_SUBSCRIPTIONS';


//Error
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';