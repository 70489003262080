import React, { Fragment, useEffect, useState } from 'react';
import './header.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import { getProfile, logOutUser } from '../Redux/ActionCreators/profileAction';
import { getContents, setLang } from '../Redux/ActionCreators/homepageAction';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './footer';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { clearErrors } from '../Redux/ActionCreators/errorAction';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile);
    const { activeLanguage, languages } = useSelector(state => state.home)
    const error = useSelector(state => state.error);

    useEffect(() => {
        // if (localStorage.getItem('userAuthToken')) {
        //     navigate('/', { replace: true })
        // }
        const firstRender = async () => {
            if (localStorage.getItem('userAuthToken')) {
                dispatch(getProfile());
            }
            dispatch(getContents());
            updateDimensions();
        }

        firstRender();
    }, [])


    const setLanguages = (e) => {
        dispatch(setLang(e));
    }




    const logout = async () => {
        dispatch(logOutUser());
        localStorage.removeItem('userAuthToken');
        localStorage.removeItem('loginDetails');
        window.location.href = '/';
    }



    const updateDimensions = () => {
        let w = window.screen.width;
        if (w <= 1024) {
            document.body.classList.add("BodyCloseNav");
        } else {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        }
    };

    const getIntial = (fname, lname) => {
        const i1 = fname?.split('')[0]
        const i2 = lname?.split('')[0]
        return i1 + i2
    }

    const { profileDetails } = profile

    const snackClosed = () => {
        dispatch(clearErrors());
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successError}
                autoHideDuration={25000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successMessage}
                autoHideDuration={25000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <div className='dashboard-container'>
                <Navbar expand="lg" className="bg-body-tertiary bg-orange">
                    <Container fluid>
                        <Navbar.Brand >
                            <div className='cursor-pointer'>
                                <img
                                    src='./logo.png' alt='' width={window.innerWidth > 767 ? 270 : 260}
                                    className='rounded logo-img'
                                    onClick={() => {
                                        setShow(false)
                                        navigate('/', { replace: true })
                                    }}
                                />
                            </div>
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="navbarScroll" aria-label="Toggle navigation" className="navbar-toggler" onClick={() => setShow(!show)} />
                        <div className={show ? 'navbar-collapse collapse show' : 'navbar-collapse collapse not-show'}>
                            <Nav
                                className="mx-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/', { replace: true })
                                }}>
                                    <span className="nav-link">Home</span>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/about-us')
                                }}>
                                    <span className="nav-link">About us</span>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/contact-us')
                                }}>
                                    <span className="nav-link">Contact us</span>
                                </li>
                                {/* <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/subscriptions')
                                }}>
                                    <span className="nav-link">Subscription</span>
                                </li> */}
                                <li className="nav-item">
                                    <span className="nav-link">
                                        <div id="google_translate_element"></div>
                                    </span>
                                </li>

                                {/* <NavDropdown title={activeLanguage} id="navbarScrollingDropdown">
                                    <li className="nav-item dropdown">
                                        {
                                            languages?.map(el => {
                                                return (
                                                    <NavDropdown.Item className='text-uppercase' onClick={() => setLanguages(el.label)}>{el.label}</NavDropdown.Item>
                                                )
                                            })
                                        }
                                    </li>
                                </NavDropdown> */}

                                {window.innerWidth > 767 ? null :
                                    localStorage.getItem('userAuthToken') ?
                                        <Fragment>
                                            <li className="nav-item" onClick={() => navigate('/profile')}>
                                                <span className="nav-link">Profile</span>
                                            </li>
                                            <li className="nav-item" onClick={() => logout()}>
                                                <span className="nav-link">Logout</span>
                                            </li>
                                        </Fragment> :
                                        <div className="d-flex nav-btns">
                                            <button onClick={() => {
                                                setShow(false)
                                                navigate('/login')
                                            }} >Login</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <button onClick={() => {
                                                setShow(false)
                                                navigate('/signup')
                                            }} >Sign up</button>
                                        </div>
                                }
                            </Nav>
                            {
                                window.innerWidth > 767 ?
                                    <div className="dashboard-profile-holder">
                                        {localStorage.getItem('userAuthToken') ?
                                            <div className='d-flex align-items-center'>
                                                <h4 className='mb-0 text-white'>{profileDetails?.firstName} {profileDetails?.lastName}</h4>&nbsp;&nbsp;
                                                <div className="dropmain-holder d-inline-block">
                                                    <div
                                                        className="drop-profile-hold initial-circle-custom"
                                                    >

                                                        {profileDetails?.image ? (
                                                            <img
                                                                src={profileDetails?.image} alt=''
                                                                onError={(e) => {
                                                                    if (e.target.src !== "./images/placeholder-image.jpg") {
                                                                        e.target.onerror = "./images/placeholder-image.jpg";
                                                                        e.target.src = "./images/placeholder-image.jpg";
                                                                    }
                                                                }}
                                                            />
                                                        ) :
                                                            getIntial(profileDetails?.firstName, profileDetails?.lastName)

                                                        }
                                                    </div>
                                                    <div className="drop-inst">

                                                        <div className="logout" onClick={() => navigate('/profile')}>
                                                            Profile
                                                        </div>
                                                        <div className="logout" onClick={() => logout()}>
                                                            Sign Out
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="d-flex nav-btns">
                                                <button onClick={() => navigate('/login')} >Login</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button onClick={() => navigate('/signup')} >Sign up</button>
                                            </div>
                                        }
                                    </div> :
                                    null

                            }
                        </div>
                    </Container>
                </Navbar>
                <div className='layout-container' style={{ paddingLeft: 0 }} >
                    <React.Suspense fallback={''}>
                        <Outlet />
                    </React.Suspense>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment >

    )
}




export default Header
