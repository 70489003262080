import React, { Fragment, useEffect, useRef, useState } from 'react';
import './home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../Redux/ActionCreators/profileAction';
import { getBanners, getBannersForMasterCatalogs, getMasterCatalogs, getBuildCatalogs, getBuildSubCatalogs, getProductTypes, getProducts, getNextProducts, getCuisines, setActiveType, setActiveProduct, resetProducts, getMealSubCatalogs, setLang } from '../../Redux/ActionCreators/homepageAction';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Slider from 'react-slick';
import { SampleNextArrow, SamplePrevArrow } from '../../utils/miscellneous';
import DynamicInput from '../../utils/dynamicInput';
import { Helmet } from "react-helmet";


export default function Home() {
    const [activeMasterCatalog, setActiveMasterCatalog] = useState('');
    const [masterSearch, setMasterSearch] = useState('');
    const [masterCatalogType, setMasterCatalogType] = useState('');
    const [activeSlug, setActiveSlug] = useState('');
    const [activeCatalog, setActiveCatalog] = useState('');
    const [activeSubCatalog, setActiveSubCatalog] = useState('');
    const [activeCuisine, setActiveCuisine] = useState('');
    const [activeProductType, setActiveProductType] = useState('');
    const [subCataLogRemark, setSubCataLogRemark] = useState('');
    const [cuisineRemark, setCuisineRemark] = useState('');
    const [productTypeRemark, setProductTypeRemark] = useState('');
    const [productRemark, setProductRemark] = useState('');
    const [subCatalogId, setSubCatalogId] = useState('');
    const [cuisineId, setCuisineId] = useState('');
    const [productTypeId, setProductTypeId] = useState('');
    const [other, setOther] = useState('');
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [search, setSearch] = useState('');
    const [age, setAge] = useState('');
    const [ageError, setAgeError] = useState('');
    const [healthCondition, setHealthCondition] = useState('');
    const [healthError, setHealthError] = useState('');
    const [goals, setGoals] = useState([{ key: 0, text: '' }]);
    const [goalError, setGoalError] = useState('');
    const [selectedOption, setSelectedOption] = useState('yes');
    const [selectedMeal, setSelectedMeal] = useState([]);
    const [mealTypeRemark, setMealTypeRemark] = useState('');
    const [food_allergies, setFood_allergies] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const recipeData = useSelector(state => state.home);
    const products = useSelector(state => state.home.products);
    const { masterCatalogs, banners, catalogs, subCatalogs, cuisines, productTypes, activeProducts, activeProductTypes, mealTypes, languages, activeLanguage } = recipeData

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            localStorage.getItem('userAuthToken') && dispatch(getProfile());
            await dispatch(getBanners());
            const response = await dispatch(getMasterCatalogs());
            if (response.payload.rows?.length) {
                await setMasterSearch(response.payload.rows[0]?.id);
                if (response.payload.rows[0]?.masterCatalogType === 'recipes') {
                    await setActiveMasterCatalog(response.payload.rows[0]?.name);
                    await setMasterCatalogType(response.payload.rows[0]?.masterCatalogType);
                    await dispatch(getBannersForMasterCatalogs(response.payload.rows[0]?.id));
                    await setActiveSlug(response.payload.rows[0]?.slug)
                    const res = await dispatch(getBuildCatalogs(response.payload.rows[0]?.id));
                    await setActiveCatalog(res.payload.rows[0]?.name);
                    const res1 = await dispatch(getBuildSubCatalogs(res?.payload?.rows[0]?.id, response.payload.rows[0]?.masterCatalogType));
                    await setActiveSubCatalog(res1?.payload?.rows[0]?.name);
                    await setSubCatalogId(res1?.payload?.rows[0]?.id);
                    const res2 = await dispatch(getCuisines(res?.payload?.rows[0]?.id));
                    await setActiveCuisine(res2?.payload?.rows[0]?.name);
                    await setCuisineId(res2?.payload?.rows[0]?.id);
                    const res3 = await dispatch(getProductTypes(res?.payload?.rows[0]?.id));
                    await setActiveProductType(res3?.payload?.rows[0]?.name);
                    await setProductTypeId(res3?.payload?.rows[0]?.id);
                    await dispatch(getProducts(1, '', res1?.payload?.rows[0]?.id, res2?.payload?.rows[0]?.id, res3?.payload?.rows[0]?.id, res3?.payload?.rows[0]?.name));
                } else {
                    await setActiveMasterCatalog(response.payload.rows[0]?.name);
                    await setMasterCatalogType(response.payload.rows[0]?.masterCatalogType);
                    await setActiveSlug(response.payload.rows[0]?.slug)
                    const res = await dispatch(getBuildCatalogs(response.payload.rows[0]?.id));
                    await setActiveCatalog(res.payload.rows[0]?.name);
                    const res1 = await dispatch(getBuildSubCatalogs(res?.payload?.rows[0]?.id, response.payload.rows[0]?.masterCatalogType));
                    await setActiveSubCatalog(res1?.payload?.rows[0]?.name);
                    await setSubCatalogId(res1?.payload?.rows[0]?.id);
                    const res2 = await dispatch(getMealSubCatalogs(res?.payload?.rows[0]?.id, 'recipes,fitness'));
                    await setSelectedMeal(res2?.payload?.rows[0]);
                    const res3 = await dispatch(getCuisines(res?.payload?.rows[0]?.id));
                    await setActiveCuisine(res3?.payload?.rows[0]?.name);
                    await setCuisineId(res3?.payload?.rows[0]?.id);
                }
            }

        }
        firstRender();
    }, [])

    const searchRecipe = async () => {
        // let globalRegex = /\bday(s)?\b/gi;
        let inputData = masterCatalogs?.filter(el => el.id === Number(masterSearch))[0]?.masterCatalogType === 'recipes' ?
            {
                activeMasterCatalog: masterCatalogs?.filter(el => el.id === Number(masterSearch))[0]?.name,
                activeMasterSlug: masterCatalogs?.filter(el => el.id === Number(masterSearch))[0]?.slug,
                activeCatalog: '',
                activeSubCatalog: '',
                subCataLogRemark: '',
                activeCuisine: '',
                cuisineRemark: '',
                activeProductTypes: '',
                productTypeRemark: '',
                activeProducts: '',
                productRemark: '',
                otherNotes: '',
                keyword: search,
                // isDay: search.match(globalRegex) ? true : false,
                page: 1,
                hash: "",
            } :
            {
                activeMasterCatalog: masterCatalogs?.filter(el => el.id === Number(masterSearch))[0]?.name,
                activeMasterSlug: masterCatalogs?.filter(el => el.id === Number(masterSearch))[0]?.slug,
                activeCatalog: '',
                activeSubCatalog: '',
                subCataLogRemark: '',
                age: '',
                health_conditions: '',
                mealType: '',
                activeCuisine: '',
                otherNotes: '',
                food_allergies: "",
                keyword: search,
                // isDay: search.match(globalRegex) ? true : false,
                page: 1,
                hash: "",
            }
        await navigate('/recipes', { state: { inputData: inputData, type: masterCatalogs?.filter(el => el.id === Number(masterSearch))[0]?.masterCatalogType, selectedOption: null } });
        setPage(1);
    }

    const onKeyDown = () => {
        this.searchRecipe()
    }

    const setLanguages = (val) => {
        dispatch(setLang(val));
    }

    useEffect(() => {
        const carouselInstance = new window.bootstrap.Carousel(carouselRef.current);
        setTimeout(() => {
            carouselInstance.cycle();
        }, 4000)
    }, [masterSearch || activeMasterCatalog])

    const setMasterCatalog = async (el) => {
        await dispatch(getBannersForMasterCatalogs(el?.id));
        await setMasterSearch(el.id);
        await setActiveMasterCatalog(el.name);
        // await goToSlide(el.id);
        await setMasterCatalogType(el.masterCatalogType);
        await setActiveSlug(el.slug);
        await setAge('');
        await setHealthCondition('');
        await setGoals([{ key: 0, text: '' }])
        const res = await dispatch(getBuildCatalogs(el?.id));
        await setActiveCatalog(res?.payload?.rows[0]?.name);
        if (res?.payload?.rows?.length) {
            if (el.masterCatalogType === 'recipes') {
                const res1 = await dispatch(getBuildSubCatalogs(res?.payload?.rows[0]?.id, el.masterCatalogType));
                await setActiveSubCatalog(res1?.payload?.rows[0]?.name);
                await setSubCatalogId(res1?.payload?.rows[0]?.id);
                const res2 = await dispatch(getCuisines(res?.payload?.rows[0]?.id));
                await setActiveCuisine(res2?.payload?.rows[0]?.name);
                await setCuisineId(res2?.payload?.rows[0]?.id);
                const res3 = await dispatch(getProductTypes(res?.payload?.rows[0]?.id));
                await setActiveProductType(res3?.payload?.rows[0]?.name);
                await setProductTypeId(res3?.payload?.rows[0]?.id);
                await setPage(1);
                await dispatch(getProducts(1, '', res1?.payload?.rows[0]?.id, res2?.payload?.rows[0]?.id, res3?.payload?.rows[0]?.id, res3?.payload?.rows[0]?.name));
            } else {
                const res1 = await dispatch(getBuildSubCatalogs(res?.payload?.rows[0]?.id, el.masterCatalogType));
                await setActiveSubCatalog(res1?.payload?.rows[0]?.name);
                await setSubCatalogId(res1?.payload?.rows[0]?.id);
                const res2 = await dispatch(getMealSubCatalogs(res?.payload?.rows[0]?.id, 'recipes,fitness'));
                await setSelectedMeal(res2?.payload?.rows[0]);
                const res3 = await dispatch(getCuisines(res?.payload?.rows[0]?.id));
                await setActiveCuisine(res3?.payload?.rows[0]?.name);
                await setCuisineId(res3?.payload?.rows[0]?.id);
            }
        } else {
            setActiveCatalog('');
            setActiveSubCatalog('');
            setActiveCuisine('')
        }

    }

    const setCatalog = async (el) => {
        await setActiveCatalog(el.name);
        if (masterCatalogType === 'recipes') {
            const res1 = await dispatch(getBuildSubCatalogs(el?.id, masterCatalogType));
            await setActiveSubCatalog(res1?.payload?.rows[0]?.name);
            await setSubCatalogId(res1?.payload?.rows[0]?.id);
            const res2 = await dispatch(getCuisines(el?.id));
            await setActiveCuisine(res2?.payload?.rows[0]?.name);
            await setCuisineId(res2?.payload?.rows[0]?.id);
            const res3 = await dispatch(getProductTypes(el?.id));
            await setActiveProductType(res3?.payload?.rows[0]?.name);
            await setProductTypeId(res3?.payload?.rows[0]?.id);
            await setPage(1);
            await dispatch(getProducts(1, '', res1?.payload?.rows[0]?.id, res2?.payload?.rows[0]?.id, res3?.payload?.rows[0]?.id, res3?.payload?.rows[0]?.name));
        } else {
            const res1 = await dispatch(getBuildSubCatalogs(el?.id, masterCatalogType));
            await setActiveSubCatalog(res1?.payload?.rows[0]?.name);
            await setSubCatalogId(res1?.payload?.rows[0]?.id);
            const res2 = await dispatch(getMealSubCatalogs(el?.id, 'recipes,fitness'));
            await setSelectedMeal(res2?.payload?.rows[0]);
            const res3 = await dispatch(getCuisines(el?.id));
            await setActiveCuisine(res3?.payload?.rows[0]?.name);
            await setCuisineId(res3?.payload?.rows[0]?.id);
        }
    }

    const setSubCatalog = async (el) => {
        setActiveSubCatalog(el.name);
        setSubCatalogId(el.id)
        await setPage(1);
        await dispatch(getProducts(1, '', el?.id, cuisineId, productTypeId, activeProductType));
    }

    const setCuisine = async (el) => {
        setActiveCuisine(el.name);
        setCuisineId(el.id)
        await setPage(1);
        await dispatch(getProducts(1, '', subCatalogId, el.id, productTypeId, activeProductType));
    }

    const setProductTypes = async (el) => {
        setProductTypeId(el.id);
        setActiveProductType(el?.name);
        dispatch(setActiveType(el));
        await setPage(1);
        if (!el.isChecked) {
            dispatch(getProducts(1, '', subCatalogId, cuisineId, el.id, el.name));
        } else {
            await dispatch(resetProducts(el.id));
            await dispatch(getProducts(1, '', subCatalogId, cuisineId, el.id, el.name));
        }

        const element = document.getElementById('product-section');
        element?.scrollIntoView({
            behavior: 'smooth'
        });


        // dispatch(getProducts(1, '', subCatalogId, cuisineId, el.id, el.name));

    }

    const fetchData = async () => {
        setPage(page + 1);
        await dispatch(getNextProducts(page + 1, keyword, subCatalogId, cuisineId, productTypeId, activeProductType));

    }

    const setProduct = (el) => {
        dispatch(setActiveProduct(el));
    }


    const searchProduct = () => {
        setPage(1);
        dispatch(getProducts(1, keyword, subCatalogId, cuisineId, productTypeId, activeProductType));
    }

    const getActiveProducts = () => {
        const prodTypeid = activeProductTypes.filter(el => el.isChecked)?.map(el => el.id);
        const filteredB = activeProducts.filter(item => prodTypeid.includes(item.productTypeId));
        const productTypes = filteredB && [...new Set(filteredB?.map(el => el.productType))];
        const content = productTypes?.map(data => { return activeProducts.filter(el => { return data === el.productType }) });
        return content;
    }

    const searchRecipes = () => {

        let allProductNames = [];
        for (let item of getActiveProducts()) {
            let productNameArr = item.filter(el => el.isChecked).map(el => el.name);
            allProductNames = [...allProductNames, ...productNameArr];
        }

        let inputData = {
            activeMasterCatalog: activeMasterCatalog,
            activeMasterSlug: activeSlug,
            activeCatalog: activeCatalog,
            activeSubCatalog: activeSubCatalog,
            subCataLogRemark: subCataLogRemark,
            activeCuisine: activeCuisine,
            cuisineRemark: cuisineRemark,
            activeProductTypes: activeProductTypes.filter(el => el.isChecked)?.map(el => el.name),
            productTypeRemark: productTypeRemark,
            activeProducts: allProductNames,
            productRemark: productRemark,
            otherNotes: other,
            // isDay: activeCatalog.match(globalRegex) ? true : false,
            page: 1,
            hash: "",
        }

        navigate('/recipes', { state: { inputData: inputData, type: masterCatalogType, selectedOption: null } });
        setPage(1);
    }

    //Fot Fitness

    const handleField = (index, event) => {
        const { name, value } = event.target;
        const newFields3 = [...goals];
        newFields3[index][name] = value;
        setGoals(newFields3);
        setGoalError('')
    };

    const addField = () => {
        setGoals([...goals, { key: goals?.length, text: '' }]);

    };

    const removeField = (idx) => {
        setGoals(goals.filter((s, index) => idx !== index));
        goals.forEach(el => {
            if (idx < el.key) {
                el.key = el.key - 1
            }
        })
    }

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    }

    const setMealSubCatalog = (e) => {
        setSelectedMeal(e)
    }



    const searchFitness = async () => {
        if (!age) {
            setAgeError(`Age can't be empty`);
        }
        else if (!healthCondition) {
            setHealthError(`Health condition can't be empty`);
        }
        else if (!goals[0]?.text) {
            setGoalError(`Please Enter at least one goal`);
        } else {

            let inputData = {
                "activeMasterCatalog": activeMasterCatalog,
                "activeMasterSlug": activeSlug,
                "activeCatalog": activeCatalog,
                "activeSubCatalog": activeSubCatalog,
                "subCataLogRemark": subCataLogRemark,
                "age": Number(age),
                "health_conditions": healthCondition,
                "goals": goals?.map(el => el.text),
                "mealType": selectedOption === 'yes' ? selectedMeal?.name : "",
                "mealTypeRemark": selectedOption === 'no' ? '' : mealTypeRemark,
                "activeCuisine": selectedOption === 'yes' ? activeCuisine : "",
                "cuisineRemark": selectedOption === 'yes' ? cuisineRemark : "",
                "food_allergies": selectedOption === 'yes' ? food_allergies : "",
                "otherNotes": other,
                "page": 1,
                "hash": ""
            }
            await navigate('/recipes', { state: { inputData: inputData, type: masterCatalogType, selectedOption: selectedOption } });
            setPage(1);
        }
    }

    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }

        ]
    };

    const carouselRef = useRef(null);

    // Function to navigate to a specific slide
    const goToSlide = (id) => {
        let slideIndex = banners?.findIndex(el => el.masterCatalog?.id === Number(id));
        if (carouselRef.current) {
            const carouselInstance = new window.bootstrap.Carousel(carouselRef.current);
            carouselInstance.pause();
            carouselInstance.to(slideIndex);
            setTimeout(() => {
                carouselInstance.cycle();
            }, 8000);
        }
    };

    return (
        <div className='homepage-holder'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mashup Now | Healthy Recipes, Weight Loss Diets & Global Cuisines</title>
                <meta name="description" content="Explore thousands of recipes on Mashup Now! From healthy recipes for dinner to body detox diets, vegan dishes, and desserts, find inspiration for every meal." />
                <link rel="canonical" href="https://www.mashupnow.com/" />
            </Helmet>
            <div className='banner-holder position-relative'>
                <div id="carouselExampleCaptions" ref={carouselRef} className="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
                    <div className="carousel-indicators">
                        {
                            banners?.map((el, index) => {
                                return (
                                    <button key={el.id} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={`${index}`} className="active" aria-current="true" aria-label={`Slide ${index}`}></button>
                                )
                            })
                        }
                    </div>
                    <div className="carousel-inner" >
                        {
                            banners?.map((el, index) => {
                                return (
                                    <div key={el.id} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                        <img src={el.image} className="d-block w-100 banner-img" alt="..." style={{ aspectRatio: '211 / 35' }} />
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className='search-sticky'>
                    <select className='search-select form-control' value={masterSearch} onChange={async (e) => {
                        await setMasterSearch(e.target.value);
                        await setMasterCatalog(masterCatalogs?.filter(el => el.id === Number(e.target.value))[0])
                    }}>
                        <option hidden>Select</option>
                        {
                            masterCatalogs?.map(el => {
                                return (
                                    <option key={el.id} value={el.id}>{el.name}</option>
                                )
                            })
                        }
                    </select>
                    <div className="has-search"  >
                        <input type="search" id='search' className="form-control" value={search}
                            placeholder="Search by keyword..."
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) { searchRecipe() }
                            }} />
                    </div>&nbsp;&nbsp;&nbsp;
                    <button className='search-btn' disabled={!(masterSearch && search)} onClick={searchRecipe}>Search</button>
                </div>
            </div>

            <div className='build-recipe padd'>
                <div className='subcatalog-cards master-catalog position-relative mt-4' >
                    {masterCatalogs?.length ?
                        <Slider {...settings}>
                            {
                                masterCatalogs?.map((el, i) => {
                                    return (
                                        <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-md-3 col-lg-3  pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setMasterCatalog(el)}>
                                            <div className='card d-flex align-items-center justify-content-center' style={{
                                                backgroundImage: `url(${el.image ? el.image : './images/placeholder-image.jpg'})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                                                border: activeMasterCatalog === el.name ? '6px solid #FF6913' : '2px solid #c5c5c7', height: 116
                                            }}  >
                                                <div className="card__body " style={{ padding: '10px 0px' }}>
                                                    <div className='wrapper'>
                                                    </div>
                                                    <h1 className='elipsis' style={{ color: '#FAFAFA', zIndex: 1, position: 'relative' }} title={el.name}>{el.name}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider> :
                        null
                    }
                </div>

            </div>
            {masterCatalogType === 'recipes' ?
                <Fragment>
                    {catalogs?.length ?
                        <div className='build-recipe'>
                            <h3>Build me a Recipe</h3>
                            <div className='recipe-cards '>
                                <div className={'row  mt-2 overflow-build-catalog'}>
                                    {
                                        catalogs?.map((el, i) => {
                                            return (
                                                <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-lg-3 col-md-3 pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setCatalog(el)}>
                                                    <div className='card' style={{ background: activeCatalog === el.name ? '#f66817' : '', border: activeCatalog === el.name ? '2px solid #FFA876' : '2px solid #c5c5c7' }}  >
                                                        <div className="card__img">
                                                            <img src={el.image ? el.image : './images/placeholder-image.jpg'} alt="" loading="lazy" onError={(e) => {
                                                                if (e.target.src !== './images/placeholder-image.jpg') {
                                                                    e.target.onerror = './images/placeholder-image.jpg';
                                                                    e.target.src = './images/placeholder-image.jpg';
                                                                }
                                                            }} />
                                                        </div>
                                                        <div className="card__body">
                                                            <h1 className='elipsis' style={{ color: activeCatalog === el.name ? '#FAFAFA' : '' }} title={el.name}>{el.name}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> :
                        null}

                    {subCatalogs?.length ?
                        <div className='build-recipe '>
                            <h3>I Want to Make...</h3>
                            <div className='subcatalog-cards'>
                                <div className='row  mt-2 overflow-sub-catalog'>
                                    {
                                        subCatalogs?.map((el, i) => {
                                            return (
                                                <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-lg-3 col-md-3 pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setSubCatalog(el)}>
                                                    <div className='card' style={{ background: activeSubCatalog === el.name ? '#f66817' : '', border: activeSubCatalog === el.name ? '2px solid #FFA876' : '2px solid #c5c5c7' }}  >
                                                        <div className="card__body" >
                                                            <h1 className='elipsis' style={{ color: activeSubCatalog === el.name ? '#FAFAFA' : '' }} title={el.name}>{el.name}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='add-box mt-4'>
                                    <input type='text' className='form-control' placeholder='Add something else...' value={subCataLogRemark} onChange={(e) => setSubCataLogRemark(e.target.value)} />&nbsp;&nbsp;
                                    <button>Add</button>
                                </div>
                            </div>
                        </div> :
                        null
                    }

                    {cuisines?.length ?
                        <div className='build-recipe'>
                            <h3>Explore Cuisines</h3>
                            <div className='recipe-cards bg-yellow'>
                                <div className={'row  mt-2 overflow-cuisine'}>
                                    {
                                        cuisines?.map((el, i) => {
                                            return (
                                                <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-md-3 col-lg-3  pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setCuisine(el)}>
                                                    <div className='card' style={{
                                                        backgroundImage: `url(${el.image ? el.image : './images/placeholder-image.jpg'})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                                                        border: activeCuisine === el.name ? '6px solid #FF6913' : '2px solid #c5c5c7', height: 116
                                                    }}  >
                                                        <div className="card__body " style={{ padding: '22px 0px' }}>
                                                            <div className='wrapper'>
                                                            </div>
                                                            <h1 className='elipsis' style={{ color: '#FAFAFA', zIndex: 1, position: 'relative' }} title={el.name}>{el.name}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='add-box mt-4'>
                                    <input type='text' className='form-control' placeholder='Add something else...' value={cuisineRemark} onChange={(e) => setCuisineRemark(e.target.value)} />&nbsp;&nbsp;
                                    <button>Add</button>
                                </div>
                            </div>
                        </div> :
                        null
                    }

                    {productTypes?.length ?
                        <div className='build-recipe'>
                            <h3>Select Product/Recipe Options</h3>
                            <div className='subcatalog-cards'>
                                <div className={'row  mt-2 overflow-prod-types'}>
                                    {
                                        productTypes?.map((el, i) => {
                                            return (
                                                <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-lg-3 col-md-3   pr-0 mt-3 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setProductTypes(el)}>
                                                    <div className='card d-flex justify-content-center align-items-center' style={{ height: 70, background: el.id === productTypeId ? '#f66817' : '', border: el.id === productTypeId ? '2px solid #FFA876' : '2px solid #c5c5c7' }}  >
                                                        <div className="card__body" >
                                                            <h1 className='elipsis' style={{ color: el.id === productTypeId ? '#FAFAFA' : '#555557' }}
                                                                title={el.name}>{el.name}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='add-box mt-4'>
                                    <input type='text' className='form-control' placeholder='Add something else...' value={productTypeRemark} onChange={(e) => setProductTypeRemark(e.target.value)} />&nbsp;&nbsp;
                                    <button>Add</button>
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    {catalogs?.length ?
                        <div className='build-recipe' id='product-section'>
                            <h3>Choose your Products/Ingredients</h3>
                            <div className='search-product'>
                                <div className="has-search"  >
                                    <span className="form-control-feedback">
                                        <img src="./svg-icons/search.svg" alt='' />
                                    </span>
                                    <input type="search" id='search' className="form-control" placeholder="Search" onChange={(e) => setKeyword(e.target.value)} />
                                </div>&nbsp;&nbsp;&nbsp;
                                <button className='search-btn' onClick={searchProduct}>Search</button>
                            </div>
                            {products?.length ?
                                <Fragment>
                                    <div style={{ background: '#F3EEEB', padding: 15, paddingTop: 0, borderRadius: 22 }}>

                                        <div id="scrollableDiv1" className='subcatalog-cards overflow-cuisine' style={{
                                            maxHeight: window.innerWidth > 767 ? 460 : 475,
                                            overflow: 'auto',
                                            overflowX: 'hidden', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, paddingRight: 10
                                        }}>
                                            <InfiniteScroll
                                                dataLength={products && products?.length} //This is important field to render the next data
                                                next={fetchData}
                                                hasMore={true}
                                                style={{ overflow: 'unset' }}
                                                scrollableTarget="scrollableDiv1"
                                            >
                                                <div className={'row mt-2'}>

                                                    {products?.map((el) => {
                                                        return (
                                                            <div key={el.id} className={window.innerWidth > 767 ? 'col-3  pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setProduct(el)}>
                                                                <div className='card' style={{ borderRadius: 22, border: el.isChecked ? '3px solid #f66817' : '2px solid #c5c5c7' }}  >
                                                                    <div className="card__body" style={{ paddingTop: 0 }}>
                                                                        <h1 className='elipsis' style={{ fontSize: 18, fontWeight: 700 }} title={el.name}>{el.name}</h1>
                                                                    </div>
                                                                    <div className="card__img">
                                                                        <img src={el.image ? el.image : './images/placeholder-image.jpg'} alt="" loading="lazy" onError={(e) => {
                                                                            if (e.target.src !== './images/placeholder-image.jpg') {
                                                                                e.target.onerror = './images/placeholder-image.jpg';
                                                                                e.target.src = './images/placeholder-image.jpg';
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='add-box mt-4'>
                                            <input type='text' className='form-control' placeholder='Add something else...' value={productRemark} onChange={(e) => setProductRemark(e.target.value)} />&nbsp;&nbsp;
                                            <button>Add</button>
                                        </div>
                                    </div>
                                </Fragment>
                                : null
                            }
                        </div >
                        : null
                    }

                    <div className='build-recipe'>
                        <h3>Add Something Else</h3>
                        <div className='something'>
                            <textarea rows={3} cols={10} className='form-control' placeholder='Write something...' onChange={(e) => setOther(e.target.value)} />
                        </div>
                    </div>

                    <div className='build-recipe'>
                        <h3>My Choices</h3>
                        <div className='my-recipe'>
                            <div className='form-group'>
                                <label>Master Catalogs</label>
                                <h4>{activeMasterCatalog}</h4>
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                            <div className='form-group'>
                                <label>Build me a recipe</label>
                                <h4>{activeCatalog}</h4>
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                            <div className='form-group'>
                                <label>I want to make</label>
                                <h4>{activeSubCatalog}</h4>
                                {subCataLogRemark && <aside>Additional : {subCataLogRemark}</aside>}
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                            <div className='form-group'>
                                <label>Cuisine</label>
                                <h4>{activeCuisine}</h4>
                                {cuisineRemark && <aside>Additional : {cuisineRemark}</aside>}
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                            <div className='form-group'>
                                <label>Product types</label>
                                <h4 className='d-flex align-items-center'>
                                    {getActiveProducts()?.map((el, i) => {
                                        return (
                                            <div key={el.id} >
                                                {el[0]?.productType}{', '}
                                            </div>
                                        )
                                    })}</h4>
                                {productTypeRemark && <aside>Additional : {productTypeRemark}</aside>}
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                            <div className='form-group'>
                                <label>Selected Products</label>
                                <h4>{getActiveProducts()?.map((el, i) => {
                                    return (
                                        <div key={el.id}>
                                            {el[0]?.productType} : {el.filter(el1 => el1.isChecked).map(el1 => el1.name).join(', ')}
                                        </div>
                                    )
                                })
                                }</h4>
                                {productRemark && <aside>Additional : {productRemark}</aside>}
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                            <div className='form-group'>
                                <label>Write Something Else</label>
                                <h4>{other}</h4>
                                <hr style={{ border: '1px solid #E6E6EA' }} />
                            </div>
                        </div>
                    </div>

                    <div className='text-center'>
                        <button className='build-btn' onClick={searchRecipes}>Build Me Now</button>
                    </div>
                </Fragment> :
                masterCatalogType === 'fitness' ?
                    <Fragment>
                        {catalogs?.length ?
                            <div className='build-recipe'>
                                <h3>Select your Health & Fitness Category</h3>
                                <div className='recipe-cards '>
                                    <div className={'row  mt-2 overflow-build-catalog'}>
                                        {
                                            catalogs?.map((el, i) => {
                                                return (
                                                    <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-lg-3 col-md-3 pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setCatalog(el)}>
                                                        <div className='card' style={{ background: activeCatalog === el.name ? '#f66817' : '', border: activeCatalog === el.name ? '2px solid #FFA876' : '2px solid #c5c5c7' }}  >
                                                            <div className="card__img">
                                                                <img src={el.image ? el.image : './images/placeholder-image.jpg'} alt="" loading="lazy" onError={(e) => {
                                                                    if (e.target.src !== './images/placeholder-image.jpg') {
                                                                        e.target.onerror = './images/placeholder-image.jpg';
                                                                        e.target.src = './images/placeholder-image.jpg';
                                                                    }
                                                                }} />
                                                            </div>
                                                            <div className="card__body">
                                                                <h1 className='elipsis' style={{ color: activeCatalog === el.name ? '#FAFAFA' : '' }} title={el.name}>{el.name}</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> :
                            null}

                        {subCatalogs?.length ?
                            <div className='build-recipe '>
                                <h3>Select your Health & Fitness Sub-Category</h3>
                                <div className='subcatalog-cards'>
                                    <div className='row  mt-2 overflow-sub-catalog'>
                                        {
                                            subCatalogs?.map((el, i) => {
                                                return (
                                                    <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-lg-3 col-md-3 pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setSubCatalog(el)}>
                                                        <div className='card' style={{ background: activeSubCatalog === el.name ? '#f66817' : '', border: activeSubCatalog === el.name ? '2px solid #FFA876' : '2px solid #c5c5c7' }}  >
                                                            <div className="card__body" >
                                                                <h1 className='elipsis' style={{ color: activeSubCatalog === el.name ? '#FAFAFA' : '' }} title={el.name}>{el.name}</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className='add-box mt-4'>
                                        <input type='text' className='form-control' placeholder='Add something else...' value={subCataLogRemark} onChange={(e) => setSubCataLogRemark(e.target.value)} />&nbsp;&nbsp;
                                        <button>Add</button>
                                    </div>
                                </div>
                            </div> :
                            null
                        }
                        <div className='build-recipe'>
                            <h3>Goal preferences</h3>
                            <div className='goal-preference'>
                                <div className={window.innerWidth > 767 ? 'd-flex align-items-center' : ''}>
                                    <div className='label'> Personal{window.innerWidth > 767 && <br />} Informtion</div>
                                    <div className='form-group'>
                                        <input type='number' className='pi-input form-control' value={age} placeholder='Age' onChange={(e) => { setAge(e.target.value) }} />
                                        {!age && <span className='error-msg'>{ageError}</span>}
                                    </div>
                                </div>
                                <div className={window.innerWidth > 767 ? 'd-flex align-items-center' : ''}>
                                    <div className='label'> Health{window.innerWidth > 767 && <br />} Conditions</div>
                                    <div className='form-group'>
                                        <input type='text' className='pi-input form-control' style={{ width: window.innerWidth > 767 ? '760px' : "" }} value={healthCondition} placeholder='Description' onChange={(e) => { setHealthCondition(e.target.value) }} />
                                        {!healthCondition && <span className='error-msg'>{healthError}</span>}
                                    </div>
                                </div>
                                <div className={window.innerWidth > 767 ? 'd-flex align-items-center' : 'd-flex align-items-center flex-wrap'}>
                                    <div className='label'> Goals</div>
                                    <DynamicInput
                                        array={goals}
                                        addField={() => addField('goal')}
                                        removeField={(idx) => removeField(idx)}
                                        handleField={(index, event) => handleField(index, event)}
                                    />
                                </div>
                                {<span className='error-msg'>{goalError}</span>}
                            </div>
                        </div>
                        <div className='build-recipe'>
                            <h3>Diet Preferences</h3>
                            <div className='goal-preference'>
                                <div className='d-flex align-items-center flex-wrap'>
                                    <div className='label'> Do You need suggestions on diet?</div>
                                    <div className={window.innerWidth > 767 ? 'ms-5' : 'mt-3'}>
                                        <div className="form-check form-check-inline custom-radio">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio1"
                                                value="yes"
                                                checked={selectedOption === 'yes'}
                                                onChange={handleOptionChange}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline custom-radio">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio2"
                                                value="no"
                                                checked={selectedOption === 'no'}
                                                onChange={handleOptionChange}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio2">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='meal-type'>
                                    <div className='label'> Meal Type</div>
                                    <div className='checkbox-group'>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox1"
                                                value="breakfast"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label me-5" htmlFor="checkbox1">
                                                BreakFast
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox2"
                                                value="brunch"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label me-5" htmlFor="checkbox2">
                                                Brunch
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox3"
                                                value="lunch"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label me-5" htmlFor="checkbox3">
                                                Lunch
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox4"
                                                value="snacks"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label me-5" htmlFor="checkbox4">
                                                Snacks
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkbox5"
                                                value="dinner"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label" htmlFor="checkbox5">
                                                Dinner
                                            </label>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        {selectedOption === 'yes' &&
                            <div className='build-recipe'>
                                <h3>Diet/Food Restrictions (Allergies)</h3>
                                <div className='d-flex justify-content-center'>
                                    <div className='add-box mt-4'>
                                        <input type='text' className='form-control' placeholder='Any Food Restrictions' value={food_allergies} onChange={(e) => setFood_allergies(e.target.value)} />&nbsp;&nbsp;
                                        <button>Add</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {selectedOption === 'yes' &&
                            (mealTypes?.length ?
                                <div className='build-recipe '>
                                    <h3>Meal Type</h3>
                                    <div className='subcatalog-cards'>
                                        <div className='row  mt-2 overflow-sub-catalog'>
                                            {
                                                mealTypes?.map((el, i) => {
                                                    return (
                                                        <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-lg-3 col-md-3 pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setMealSubCatalog(el)}>
                                                            <div className='card' style={{ background: selectedMeal?.name === el.name ? '#f66817' : '', border: selectedMeal?.name === el.name ? '2px solid #FFA876' : '2px solid #c5c5c7' }}  >
                                                                <div className="card__body" >
                                                                    <h1 className='elipsis' style={{ color: selectedMeal?.name === el.name ? '#FAFAFA' : '' }} title={el.name}>{el.name}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='add-box mt-4'>
                                            <input type='text' className='form-control' placeholder='Add something else...' value={mealTypeRemark} onChange={(e) => setMealTypeRemark(e.target.value)} />&nbsp;&nbsp;
                                            <button>Add</button>
                                        </div>
                                    </div>
                                </div> :
                                null)
                        }
                        {
                            selectedOption === 'yes' &&
                                cuisines?.length ?
                                <div className='build-recipe'>
                                    <h3>Explore Cuisines</h3>
                                    <div className='recipe-cards bg-yellow'>
                                        <div className={'row  mt-2 overflow-cuisine'}>
                                            {
                                                cuisines?.map((el, i) => {
                                                    return (
                                                        <div key={el.id} className={window.innerWidth > 767 ? 'col-3 col-md-3 col-lg-3  pr-0 mt-1 mb-2 d-flex align-self-stretch cursor-pointer' : 'card-align col-6  mt-1 mb-2 d-flex align-self-stretch cursor-pointer'} onClick={() => setCuisine(el)}>
                                                            <div className='card' style={{
                                                                backgroundImage: `url(${el.image ? el.image : './images/placeholder-image.jpg'})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                                                                border: activeCuisine === el.name ? '6px solid #FF6913' : '2px solid #c5c5c7', height: 116
                                                            }}  >
                                                                <div className="card__body " style={{ padding: '22px 0px' }}>
                                                                    <div className='wrapper'>
                                                                    </div>
                                                                    <h1 className='elipsis' style={{ color: '#FAFAFA', zIndex: 1, position: 'relative' }} title={el.name}>{el.name}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='add-box mt-4'>
                                            <input type='text' className='form-control' placeholder='Add something else...' value={cuisineRemark} onChange={(e) => setCuisineRemark(e.target.value)} />&nbsp;&nbsp;
                                            <button>Add</button>
                                        </div>
                                    </div>
                                </div> :
                                null
                        }

                        <div className='build-recipe'>
                            <h3>Write Something Else</h3>
                            <div className='something'>
                                <textarea rows={3} cols={10} className='form-control' placeholder='Write something...' onChange={(e) => setOther(e.target.value)} />
                            </div>
                        </div>

                        <div className='build-recipe'>
                            <h3>My Choices</h3>
                            <div className='my-recipe'>
                                <div className='form-group'>
                                    <label>Master Catalogs</label>
                                    <h4>{activeMasterCatalog}</h4>
                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                                <div className='form-group'>
                                    <label>Build me a recipe</label>
                                    <h4>{activeCatalog}</h4>
                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                                <div className='form-group'>
                                    <label>I want to make</label>
                                    <h4>{activeSubCatalog}</h4>
                                    {subCataLogRemark && <aside>Additional : {subCataLogRemark}</aside>}
                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                                <div className='form-group'>
                                    <label>Personal Inforamtion</label>
                                    <h4>Age : {age}</h4>
                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                                <div className='form-group'>
                                    <label>Health Conditions</label>
                                    <h4>{healthCondition}</h4>
                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                                <div className='form-group'>
                                    <label>Goals</label>
                                    {
                                        goals?.map((el, i) => {
                                            return (
                                                <h4 key={el.text}>{`Goal${i + 1}`} - {el.text}</h4>
                                            )
                                        })
                                    }

                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                                {
                                    selectedOption === 'yes' &&
                                    <Fragment>
                                        <div className='form-group'>
                                            <label>Diet/Food Restrictions (Allergies)</label>
                                            <h4>{food_allergies}</h4>
                                            <hr style={{ border: '1px solid #E6E6EA' }} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Meal Type</label>
                                            <h4>{selectedMeal?.name}</h4>
                                            {mealTypeRemark && <aside>Additional : {mealTypeRemark}</aside>}
                                            <hr style={{ border: '1px solid #E6E6EA' }} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Cuisines</label>
                                            <h4>{activeCuisine}</h4>
                                            {cuisineRemark && <aside>Additional : {cuisineRemark}</aside>}
                                            <hr style={{ border: '1px solid #E6E6EA' }} />
                                        </div>
                                    </Fragment>}
                                <div className='form-group'>
                                    <label>Write Something Else</label>
                                    <h4>{other}</h4>
                                    <hr style={{ border: '1px solid #E6E6EA' }} />
                                </div>
                            </div>
                        </div>

                        <div className='text-center'>
                            <button className='build-btn' onClick={searchFitness}>Advice Me Now</button>
                        </div>
                    </Fragment> :
                    null
            }
        </div >
    )
}

