import React, { Fragment, useEffect, useState } from 'react';
import './myfavorite.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteRecipes, removeFavRecipes, getPlatingImages } from '../../Redux/ActionCreators/homepageAction';
import { Accordion } from 'react-bootstrap';
import TablePagination from '../../utils/tablePagination';
import Lightbox from '../../utils/lightBox';


export default function FavoriteRecipes() {
    const [active, setActive] = useState('-1');
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [openBox, setOpenBox] = useState(false);
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { favRecipes } = useSelector(state => state.home);

    useEffect(() => {
        dispatch(getFavoriteRecipes(1, ''))
    }, [])

    const searchKeyword = async () => {
        setPage(1);
        await dispatch(getFavoriteRecipes(1, search));
    }

    const resetkeyword = async () => {
        setSearch('');
        await dispatch(getFavoriteRecipes(1, ''));
    }

    const removeFavorites = async (rid, id) => {
        let obj = {
            "recipeId": rid,
            "isFavorite": false
        }
        await dispatch(removeFavRecipes(obj, id));
    }

    const handleAccordion = (i) => {
        if (Number(active) === i) {
            setActive(-1);
        } else {
            setActive(i);
        }
    }

    const openImage = (e, img) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenBox(true);
        setImage(img)
    }

    const goToPrev = () => {
        setPage(page - 1);
        dispatch(getFavoriteRecipes(page - 1, search));
    }

    const goToNext = () => {
        setPage(page + 1);
        dispatch(getFavoriteRecipes(page + 1, search));
    }

    const getPlatingImage = async (detail, i) => {
        await setIsLoading(true);
        await dispatch(getPlatingImages(detail, i, 'favorite'));
        await setIsLoading(false);
    }

    return (

        <div className='favorite-recipes'>
            <h2 className="d-flex">
                Favorite Recipes
            </h2>
            {favRecipes &&
                <div className='fav-header'>
                    <div className='fav-input'>
                        <input type='search' placeholder='Search By Name' value={search} className='form-control' onChange={(e) => setSearch(e.target.value)} />
                        <button onClick={searchKeyword} disabled={!search}> <img src='./svg-icons/search-icon.svg' width={20} alt='' /> &nbsp;Search</button>&nbsp;&nbsp;
                        <button onClick={resetkeyword} disabled={!search} className='btn-secondary'> Clear</button>
                    </div>
                </div>}
            < div className='content-holder' >
                <div className='content'>
                    <Accordion defaultActiveKey={`${active}`} id='accord' >
                        {
                            favRecipes?.rows?.map((el, i) => {
                                return (
                                    <Accordion.Item key={i} eventKey={`${i}`}>
                                        <Accordion.Header className={Number(active) === i ? 'active-acc' : ''} onClick={() => handleAccordion(i)}>
                                            <div className='d-flex justify-content-between w-100 flex-wrap' style={{ background: Number(active) === i ? '#ffefe5' : '' }}>
                                                <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                    <h2 className='d-flex justify-content-between align-items-center'>{el.recipe.data?.recipe_name}
                                                        <img src='./svg-icons/heart-filled.svg' className='mt-15' alt='' onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            removeFavorites(el.recipe?.id, el.id);
                                                        }} />
                                                    </h2>
                                                    {window.innerWidth < 767 &&
                                                        <div className='recipe-img-box'>
                                                            <img src={el.recipe.data.recipe_image || '/images/placeholder-image.gif'} alt='' className='recipe-img' />
                                                        </div>
                                                    }
                                                    <div className='mt-4'>
                                                        {
                                                            el.ingredients && (
                                                                <div>
                                                                    <h3>Ingredients: (Serving {el.recipe.data.serving})</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.recipe.data.ingredients.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {window.innerWidth > 767 ?
                                                    <div className={window.innerWidth > 767 ? 'w-50' : 'w-100'}>
                                                        <div className='recipe-img-box'>
                                                            <img src={el.recipe.data.recipe_image || '/images/placeholder-image.gif'} alt='' className='recipe-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, el.recipe.data.recipe_image) : null} />
                                                        </div>
                                                    </div> :
                                                    null
                                                }
                                            </div>

                                        </Accordion.Header>
                                        <Accordion.Body className={Number(active) === i ? 'active-acc-body' : ''}>
                                            <div className='row'>
                                                <div className='col-md-6 col-12'>
                                                    <div className='mt-4'>
                                                        {
                                                            el.recipe.data.instructions && (
                                                                <div>
                                                                    <h3>Instructions:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.recipe.data.instructions.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.recipe.data.suggestions && (
                                                                <div>
                                                                    <h3>Suggestions</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.recipe.data.suggestions.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.recipe.data.wellness_tips && (
                                                                <div>
                                                                    <h3>Wellness Tips</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.recipe.data.wellness_tips.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    {window.innerWidth > 767 &&
                                                        <Fragment>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.recipe.data.plating_creativity && (
                                                                        <div>
                                                                            <h3>Plating Creativity</h3>
                                                                            {/* <p dangerouslySetInnerHTML={{ __html: el.recipe.data.plating_creativity.replaceAll("\n", "<br>") }}></p> */}

                                                                            <p>
                                                                                {el.recipe.data?.plating_creativity.map(item => <div key={item}>{item}</div>)}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            {!el?.images?.length ?
                                                                <div className='view-img'>
                                                                    <button onClick={() => getPlatingImage(el?.recipe?.data, i)} disabled={isLoading}>{isLoading ? 'Loading Images ...' : 'View Images'}</button>
                                                                </div> :
                                                                null
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                                <div className='recipe-right col-md-6 col-12'>
                                                    <div className='mt-4'>
                                                        {
                                                            el.recipe.data?.nutrition && (
                                                                <div>
                                                                    <h3>Nutrition:</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.recipe.data.nutrition.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-4'>
                                                        {
                                                            el.recipe.data.burn_calories && (
                                                                <div>
                                                                    <h3>Burn Calories</h3>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.recipe.data.burn_calories.replaceAll("\n", "<br>") }}></p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    {window.innerWidth < 767
                                                        &&
                                                        <Fragment>
                                                            <div className='mt-4'>
                                                                {
                                                                    el.recipe.data.plating_creativity && (
                                                                        <div>
                                                                            <h3>Plating Creativity</h3>
                                                                            {/* <p dangerouslySetInnerHTML={{ __html: el.recipe.data.plating_creativity.replaceAll("\n", "<br>") }}></p> */}

                                                                            <p>
                                                                                {el.recipe.data?.plating_creativity.map(item => <div key={item}>{item}</div>)}
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            {!el?.images?.length ?
                                                                <div className='view-img'>
                                                                    <button onClick={() => getPlatingImage(el?.recipe?.data, i)} disabled={isLoading}>{isLoading ? 'Loading Images ...' : 'View Images'}</button>
                                                                </div> :
                                                                null
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            </div>
                                            <div className={window.innerWidth > 767 ? 'row mt-4 ms-2' : 'row ps-0 mt-4'}>
                                                {el?.images?.length ?
                                                    el?.images.slice(0, 5)?.map((image, index) => {
                                                        return (
                                                            <div key={index} className={index === 0 ? (window.innerWidth > 767 ? 'col-md-2 col-6  mt-2 text-center' : 'col-md-2 col-6 ps-0 mt-2 text-center') : (window.innerWidth > 767 ? 'col-md-2 col-6 ps-0 padd mt-2 text-center' : 'col-md-2 col-6 ps-0 padd1 mt-2 text-center')} >
                                                                <img src={image} alt='' className='plating-img' onClick={(e) => window.innerWidth > 767 ? openImage(e, image) : null} />
                                                            </div>
                                                        )
                                                    }) :
                                                    null
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion >
                    {favRecipes?.rows?.length > 0 ?
                        <div>
                            <TablePagination
                                goToPrev={goToPrev}
                                goToNext={goToNext}
                                data={favRecipes}
                            />
                        </div> :
                        null
                    }
                </div>
            </div>
            {/* ########################## Lightbox modal ####################### */}
            <Lightbox
                openBox={openBox}
                image={image}
                closeBox={() => setOpenBox(false)}
            />
        </div>

    )
}

